import React, { useEffect, useState } from "react";
import { SolarSavings, SolarSystem, Coordinate } from "types";
import { Constants, Enums, LeadGen } from "utils";
import { Combines, Validations } from "helpers";
import { Common, Embedded } from "components";
import { useLocation } from "react-router-dom";
import { useApp, useStep } from "contexts";

type Props = {
  leadId: string;
  proposalId: string;
  onReady?: () => void;
  onError: () => void;
  sharpBorder?: boolean;
  coordinate: Coordinate;
  onGenerate: (param: object) => void;
  onProductionUpdate?: (data: SolarSystem) => void;
  onProposalCostsUpdate?: (data: SolarSavings) => void;
};

export const Iframe = ({ sharpBorder = false, ...props }: Props) => {
  const { information } = useStep();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [status, setStatus] = useState<Enums.EnumIframeStatus>(
    Enums.EnumIframeStatus.Ping
  );

  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const iframeUrl = Combines.interpolate(Constants.AERIALYTIC.IFRAME_ENDPOINT, {
    apiKey: queryParams.get("apiKey"),
  });

  const isGenerated = Validations.hasValue(props.leadId, props.proposalId);
  const canGenerate =
    props.coordinate &&
    Validations.hasValue(
      String(props.coordinate.lat),
      String(props.coordinate.lng)
    );

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener("message", handleMessage);

    // Make sure the iframe is ready to handle interactions
    setIntervalId(setInterval(() => sendMessage(LeadGen.ping()), 2000));

    // Clean up the event listener when the component unmounts
    return () => {
      clearInterval(intervalId);
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // if the iframe has been mounted, it stop the ping timer and tries to load/generate a proposal
  useEffect(() => {
    if (
      [Enums.EnumIframeStatus.Ping, Enums.EnumIframeStatus.Generated].includes(
        status
      )
    ) {
      clearInterval(intervalId);
      handleLoadProposal();
    } else if (status === Enums.EnumIframeStatus.Ready) {
      props.onReady && props.onReady();
    } else if (status === Enums.EnumIframeStatus.Error) {
      props.onError();
    }
  }, [isGenerated, status]);

  // the iframe has been loaded with some content
  function handleStatusChangeCallback(state: Enums.EnumIframeStatus) {
    setStatus(state);
  }

  // the iframe has been requested to load a proposal (if possible)
  function handleLoadProposal() {
    if (isGenerated) {
      sendMessage(LeadGen.loadProposal(props.leadId, props.proposalId));
    } else handleGenerateProposal();
  }

  // the iframe has been requested to generate a lead/proposal (if possible)
  function handleGenerateProposal() {
    if (canGenerate) {
      sendMessage(
        LeadGen.generate(props.coordinate, Constants.AERIALYTIC.USAGE, information)
      );
    }
  }

  // the iframe has generated a lead/proposal
  function handleGenerateCallback(
    state: Enums.EnumIframeStatus,
    param: object
  ) {
    setStatus(state);
    props.onGenerate(param);
  }

  // an error has been received from the iframe
  function handleErrorCallback(
    state: Enums.EnumIframeStatus.Error,
    {
      code,
      message,
    }: {
      code: number;
      message: string;
    }
  ) {
    setStatus(state);
  }

  function handleMessage(event: MessageEvent) {
    const { data } = event;

    try {
      const callbackActions = {
        Error: () =>
          handleErrorCallback(Enums.EnumIframeStatus.Error, data.data),
        Production: () => {
          handleStatusChangeCallback(Enums.EnumIframeStatus.Ready);
          props.onProductionUpdate && props.onProductionUpdate(data.data);
        },
        ProposalCosts: () => {
          handleStatusChangeCallback(Enums.EnumIframeStatus.Ready);
          props.onProposalCostsUpdate && props.onProposalCostsUpdate(data.data);
        },
        NewLead: () =>
          handleGenerateCallback(Enums.EnumIframeStatus.Ready, {
            leadId: data.data,
          }),
        NewProposal: () =>
          handleGenerateCallback(Enums.EnumIframeStatus.Ready, {
            proposalId: data.data,
          }),
        Ping: () =>
          handleStatusChangeCallback(Enums.EnumIframeStatus.Generated),
        Generate: () =>
          handleStatusChangeCallback(Enums.EnumIframeStatus.Generated),
        Loaded: () => handleStatusChangeCallback(Enums.EnumIframeStatus.Ready),
        "proposal.LoadProposal": () =>
          handleStatusChangeCallback(Enums.EnumIframeStatus.Ready),
        Shading: () => handleStatusChangeCallback(Enums.EnumIframeStatus.Ready),
        PanelPlacement: () =>
          handleStatusChangeCallback(Enums.EnumIframeStatus.Ready),
      } as any;

      callbackActions[data.callbackType] &&
        callbackActions[data.callbackType]();
    } catch (error) {
      console.error("Error parsing iframe response:", error);
    }
  }

  function sendMessage(message: any) {
    if (iframeRef && iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(message, "*");
    }
  }

  return (
    <>
      <Common.Embedded
        ref={iframeRef}
        src={iframeUrl}
        title="Embedded Iframe"
        sharpBorder={sharpBorder}
        hidden={status !== Enums.EnumIframeStatus.Ready}
      />
      {status !== Enums.EnumIframeStatus.Ready && (
        <Embedded.Progress status={status} generated={isGenerated} />
      )}
    </>
  );
};
