import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import {
  Custom,
  Dialog,
  Embedded,
  Form,
  Popup,
  Progress,
  Tab,
} from "components";
import { useApp, useMap, useService, useTheme } from "contexts";
import { IMarker } from "interfaces";
import { Coordinate, SolarSystem } from "types";
import { Enums } from "utils";

// icons
import {
  DeleteRounded,
  DesignServicesRounded,
  EditRounded,
  LaunchRounded,
} from "@mui/icons-material";

type Props = {
  open: boolean;
  marker: IMarker | null;
  onOpen?: () => void;
  onDelete?: (id: string, savedListId: string | null) => void;
  onClose?: (marker: IMarker | null) => void;
  action: Enums.EnumMarkerContextMenu;
};

export const Property = ({
  action = Enums.EnumMarkerContextMenu.View,
  ...props
}: Props) => {
  const { t } = useApp();
  const { setMarkers } = useMap();
  const { theme } = useTheme();
  const { lead } = useService();

  // it controls the state of the iframe
  const [hasLoaded, setLoaded] = useState<boolean>(false);
  // it controls whether the generating button is enabled
  const [isGenerating, setGenerating] = useState<boolean>(false);

  // it controls whether the editing form is being shown
  const [isEditing, setEditing] = useState<boolean>(false);
  // it controls whether the designing form (before generation) is being shown
  const [isDesigning, setDesigning] = useState<boolean>(false);

  // it handles the information of the popup
  const [marker, setMarker] = useState<IMarker | null>(null);
  // it handles the information coming from the iframe
  const [production, setProduction] = useState<SolarSystem | null>(null);

  // it controls the dialog state
  const [isDeleteDialogOpened, setDeleteDialog] = useState<boolean>(false);
  const [isClosingDialogOpened, setClosingDialog] = useState<boolean>(false);

  useEffect(() => {
    setMarker(props.marker);
    if (action === Enums.EnumMarkerContextMenu.Generate) {
      setDesigning(true);
    } else if (action === Enums.EnumMarkerContextMenu.Edit) {
      setEditing(true);
    } else if (action === Enums.EnumMarkerContextMenu.View) {
      setEditing(false);
      setDesigning(false);
    }
  }, [action, props.open]);

  function handleClosingDialogOpen() {
    if (isGenerating) setClosingDialog(true);
    else handleClosingConfirm();
  }

  function handleClosingDialogCancel() {
    setClosingDialog(false);
  }

  async function handleClosingConfirm() {
    handleClosingDialogCancel();

    if (marker) {
      setMarkers(marker);
      await lead.updateMarker(marker.id, marker);
      setMarker(null);
    }

    // reset all the states
    setLoaded(false);
    setEditing(false);
    setDesigning(false);
    setGenerating(false);
    setProduction(null);

    props.onClose && props.onClose(marker);
  }

  function handleDeleteDialogCancel() {
    setDeleteDialog(false);
  }

  function handleDeleteConfirm() {
    handleDeleteDialogCancel();
    handleClosingConfirm();
    marker && props.onDelete && props.onDelete(marker.id, marker.savedListId);
  }

  function handleLoaded() {
    setLoaded(true);
    setGenerating(false);
    handleMarkerUpdate({ generated: true } as IMarker);
  }

  function handleError() {
    // TODO : it should be implemented to handle error
  }

  function handleGenerate(param: object) {
    handleMarkerUpdate({ ...param } as IMarker);
  }

  function handleProductionUpdate(data: SolarSystem) {
    setProduction(data);
  }

  function handleGenerateClick() {
    setDesigning(true);
  }

  function handleDesigningSubmit(marker: IMarker) {
    setDesigning(false);
    setGenerating(true);
    handleMarkerUpdate(marker);
  }

  function handleViewProposalClick() {
    // TODO : it should be implemented to open the proposal page
  }

  function handleViewLeadClick() {
    // TODO : it should be implemented to open the lead page (CRM)
  }

  function handleEditClick() {
    setEditing(true);
    setLoaded(false);
  }

  function handleEditingSubmit(marker: IMarker) {
    setEditing(false);
    handleMarkerUpdate(marker);
  }

  function handleEditingCancel() {
    setEditing(false);
  }

  function handleDeleteClick() {
    setDeleteDialog(true);
  }

  async function handleMarkerUpdate(marker: IMarker) {
    setMarker((currentMarker: IMarker | null) => {
      const newMarker = currentMarker
        ? { ...currentMarker, ...marker }
        : marker;
      return newMarker;
    });
  }

  return (
    <Popup.Root
      open={props.open}
      title={t.title.property}
      onOpen={props.onOpen}
      onClose={handleClosingDialogOpen}
      sx={{
        width: 600,
        top: theme.spacing.default,
        right: theme.spacing.default,
        bottom: theme.spacing.default,
      }}
    >
      {marker && (
        <Box width={1} height={1}>
          {!isEditing && !isDesigning && (
            <Stack width={1} height={1} alignItems="center">
              {(marker.generated || isGenerating) && (
                <Box
                  width={1}
                  height={1}
                  minHeight={!hasLoaded || !marker.generated ? 360 : undefined}
                >
                  <Embedded.Iframe
                    sharpBorder
                    coordinate={
                      { lat: marker.lat, lng: marker.lng } as Coordinate
                    }
                    onReady={handleLoaded}
                    leadId={marker.leadId}
                    proposalId={marker.proposalId}
                    onError={handleError}
                    onGenerate={handleGenerate}
                    onProductionUpdate={handleProductionUpdate}
                  />
                </Box>
              )}
              {!marker.generated && !isGenerating && (
                <Box width={1} height={150} position="relative">
                  <Progress.NoRecord
                    height={1}
                    message={t.message.placeholder.no_design}
                  />
                </Box>
              )}
              {!isGenerating && (!marker.generated || hasLoaded) && (
                <>
                  <Box width={1} p={theme.spacing.md}>
                    <Stack direction="row" justifyContent="space-evenly">
                      {!marker.generated && (
                        <Custom.IconButton
                          outlined
                          label={t.action.generate_design}
                          onClick={handleGenerateClick}
                        >
                          <DesignServicesRounded />
                        </Custom.IconButton>
                      )}
                      {marker.generated && (
                        <>
                          <Custom.IconButton
                            outlined
                            label={t.action.view_proposal}
                            onClick={handleViewProposalClick}
                          >
                            <LaunchRounded />
                          </Custom.IconButton>
                          <Custom.IconButton
                            outlined
                            label={t.action.view_info}
                            onClick={handleViewLeadClick}
                          >
                            <LaunchRounded />
                          </Custom.IconButton>
                        </>
                      )}
                      <Custom.IconButton
                        outlined
                        label={t.action.edit}
                        onClick={handleEditClick}
                      >
                        <EditRounded />
                      </Custom.IconButton>
                      <Custom.IconButton
                        outlined
                        label={t.action.delete}
                        onClick={handleDeleteClick}
                        iconColor={theme.color.status.error.color}
                      >
                        <DeleteRounded />
                      </Custom.IconButton>
                    </Stack>
                  </Box>
                  <Custom.Divider />
                  <Custom.Tabs
                    items={[
                      {
                        key: "design",
                        label: t.title.design_details,
                        children: production ? (
                          <Tab.DesignInfo production={production} />
                        ) : (
                          <Progress.NoRecord
                            height={150}
                            message={t.message.placeholder.no_data}
                          />
                        ),
                      } as Custom.TabProps,
                      {
                        key: "house",
                        label: t.title.house_details,
                        children: <Tab.HouseInfo marker={marker} />,
                      } as Custom.TabProps,
                      {
                        key: "owner",
                        label: t.title.owner_details,
                        children: <Tab.OwnerInfo marker={marker} />,
                      } as Custom.TabProps,
                    ]}
                  />
                </>
              )}
            </Stack>
          )}
          {isDesigning && (
            <Box width={1} pb={theme.spacing.md}>
              <Form.Design marker={marker} onSubmit={handleDesigningSubmit} />
            </Box>
          )}
          {isEditing && (
            <Box width={1} pb={theme.spacing.md}>
              <Form.Property
                marker={marker}
                onCancel={handleEditingCancel}
                onSubmit={handleEditingSubmit}
              />
            </Box>
          )}
        </Box>
      )}
      {!marker && <Progress.PageProcess />}
      <Dialog.Confirmation
        title={t.dialog.closing.title}
        open={isClosingDialogOpened}
        onConfirm={handleClosingConfirm}
        onCancel={handleClosingDialogCancel}
        message={t.dialog.closing.message}
      />
      <Dialog.Confirmation
        title={t.dialog.delete.title}
        open={isDeleteDialogOpened}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteDialogCancel}
        message={t.dialog.delete.message}
      />
    </Popup.Root>
  );
};
