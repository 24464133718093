import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useApp } from "contexts";
import { SolarSystem } from "types";
import { Conversions } from "helpers";
import { Constants } from "utils";

// icons
import {
  BusinessRounded,
  CalendarMonthRounded,
  ContentCopyRounded,
  ElectricMeterRounded,
  RoofingRounded,
} from "@mui/icons-material";

type Props = {
  production: SolarSystem;
};

export const DesignInfo = ({ production }: Props) => {
  const { t } = useApp();

  function handleCopyClipboardClick(value: string) {
    navigator.clipboard.writeText(value);
  }

  return (
    <List sx={{ width: 1 }}>
      <ListItemButton
        onClick={() =>
          handleCopyClipboardClick(String(production.numSelectedPanels))
        }
      >
        <ListItemIcon>
          <RoofingRounded />
        </ListItemIcon>
        <ListItemText
          primary={t.label.num_panels}
          secondary={production.numSelectedPanels ?? t.placeholder.no_value}
        />
        <ContentCopyRounded />
      </ListItemButton>

      <ListItemButton
        onClick={() => handleCopyClipboardClick(String(production.offset))}
      >
        <ListItemIcon>
          <CalendarMonthRounded />
        </ListItemIcon>
        <ListItemText
          primary={t.label.offset}
          secondary={
            Conversions.toUnit(production.offset, Constants.UNIT.PERCENT) ??
            t.placeholder.no_value
          }
        />
        <ContentCopyRounded />
      </ListItemButton>

      <ListItemButton
        onClick={() => handleCopyClipboardClick(production.yearlyProduction)}
      >
        <ListItemIcon>
          <ElectricMeterRounded />
        </ListItemIcon>
        <ListItemText
          primary={t.label.yearly_production}
          secondary={production.yearlyProduction ?? t.placeholder.no_value}
        />
        <ContentCopyRounded />
      </ListItemButton>

      <ListItemButton
        onClick={() => handleCopyClipboardClick(production.systemSize)}
      >
        <ListItemIcon>
          <BusinessRounded />
        </ListItemIcon>
        <ListItemText
          primary={t.label.system_size}
          secondary={production.systemSize ?? t.placeholder.no_value}
        />
        <ContentCopyRounded />
      </ListItemButton>
    </List>
  );
};
