import { Custom } from "components";
import { useApp, useService, useTheme } from "contexts";
import { useForm } from "hooks";
import { Enums } from "utils";
import { Auxiliars } from "helpers";
import { Box, Grid, Stack } from "@mui/material";
import { IMarker } from "interfaces";

export const DesignFields = {
  average_bill: "average_bill",
  electricity_usage: "electricity_usage",
};

export type DesignForm = {
  [key: string]: any;
  average_bill: number;
  electricity_usage: number;
};

export const DesignInitialState: DesignForm = {
  average_bill: 0,
  electricity_usage: 0,
};

type Props = {
  marker: IMarker;
  onSubmit: (marker: IMarker) => void;
};

export const Design = ({ marker, ...props }: Props) => {
  const { theme } = useTheme();
  const { lead } = useService();
  const { t, setFeedback } = useApp();

  const { onChange, onSubmit, values } = useForm<DesignForm>(
    { ...DesignInitialState, ...marker } as DesignForm,
    undefined,
    submitCallback
  );

  async function submitCallback() {
    const newValues = Auxiliars.removeFromObject(values) as IMarker;
    const { status, payload } = await lead.updateMarker(marker.id, newValues);
    if (status === Enums.EnumResponse.Success) {
      props.onSubmit && props.onSubmit(newValues);
    } else {
      setFeedback({
        message: t.message.auth.wrong_credentials,
        severity: Enums.EnumFeedback.Error,
      });
    }
  }

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Grid container p={theme.spacing.md}>
        <Grid item xs={12} mb={theme.spacing.md}>
          <Stack gap={theme.spacing.md}>
            <Grid container spacing={theme.spacing.md}>
              <Grid item xs={12}>
                <Custom.Typography variant="h5">
                  {t.label.average_bill}
                </Custom.Typography>
                <Box p={theme.spacing.sm}>
                  <Custom.Slider
                    name={DesignFields.average_bill}
                    min={0}
                    max={500}
                    step={20}
                    onChange={(event: any) => onChange(event)}
                    value={values.average_bill}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Custom.Typography variant="h5">
                  {t.label.electricity_usage}
                </Custom.Typography>
                <Box p={theme.spacing.sm}>
                  <Custom.Slider
                    name={DesignFields.electricity_usage}
                    min={2000}
                    max={20000}
                    step={500}
                    size="medium"
                    onChange={(event: any) => onChange(event)}
                    value={values.electricity_usage}
                  />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" px={theme.spacing.md}>
        <Custom.Button submit>{t.action.submit}</Custom.Button>
      </Stack>
    </Box>
  );
};
