import { useApp, useStep, useTheme } from "contexts";
import { Box, Stack } from "@mui/material";
import { EmailSend } from "assets/images";
import { Custom } from "components";
import { Enums } from "utils";

type Props = {};

export const Email = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const { goTo, setInformation } = useStep();

  function handleRestartClick() {
    setInformation(null);
    goTo(Enums.EnumEmbeddedStep.Survey);
  }

  return (
    <Stack height={1} alignItems="center" justifyContent="center">
      <Stack spacing={theme.spacing.xxl} alignItems="center">
        <Box width={100} height={100} component="img" src={EmailSend} />
        <Stack spacing={theme.spacing.xl} alignItems="center">
          <Custom.Typography variant="h3" weight="bold">
            The design has been sent to you via email.
          </Custom.Typography>
          <Stack direction="row" spacing={theme.spacing.xl}>
            <Custom.Button secondary onClick={handleRestartClick}>
              {t.action.restart}
            </Custom.Button>
            <Custom.Button
              onClick={() => goTo(Enums.EnumEmbeddedStep.Appointment)}
            >
              {t.action.make_appointment}
            </Custom.Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
