import { useEffect, useState } from "react";
import { useApp, useService, useStep, useTheme } from "contexts";
import { Grid, Stack } from "@mui/material";
import { Constants, Enums } from "utils";
import { Validations } from "helpers";
import { Controller, Custom, Datetime, Progress } from "components";
import { EmbeddedUser, GoogleCalendarEvent } from "types";

type Props = {};

export const Appointment = (props: Props) => {
  const { theme } = useTheme();
  const { embedded, google } = useService();
  const { locale, t } = useApp();
  const { goTo, information, setInformation, setFeedback, user } = useStep();

  const [slot, setSlot] = useState<string>();
  const [selected, setSelected] = useState<Date>(new Date());
  const [slots, setSlots] = useState<Custom.ChipProps[]>([]);

  const isValid = information.appointment && Validations.hasValue(slot);

  useEffect(() => {
    const fetchData = async () => fetchSlots(selected);
    fetchData();
  }, []);

  async function handleMakeAppointmentClick() {
    if (isValid) {
      try {
        const { length } = user.configuration.appointment;

        const eventId = await google.insert(
          user.configuration.google.calendarId,
          information.appointment.date,
          information.appointment.time,
          length,
          {
            location: information.map.address,
            summary: `${information.quote.firstName} ${information.quote.lastName}`,
            description: `${information.quote.email}\n${information.quote.phone}`,
          } as GoogleCalendarEvent
        );

        if (eventId) {
          const newInformation = {
            ...information,
            appointment: { eventId },
          } as EmbeddedUser;
          const { status, payload } = await embedded.insertLead(newInformation, "");
          if (status === Enums.EnumResponse.Success) {
            setFeedback(Enums.EnumEmbeddedStepFeedback.Appointment);
            goTo(Enums.EnumEmbeddedStep.Feedback);
          } else {
            await google.remove(user.configuration.google.calendarId, eventId);
          }
        }
      } catch (err) {}
    }
  }

  async function fetchSlots(date: Date) {
    const { start, end, length } = user.configuration.appointment;
    const response = await google.availability(
      user.configuration.google.calendarId,
      date,
      length,
      start,
      end
    );
    setSlots(response);
  }

  function handleSelectDate(value: Date): void {
    setSelected(value);
    fetchSlots(value);
  }

  function handleSelectSlot(value: string[]): void {
    const newValue = value.length > 0 ? value[0] : "";
    setInformation({
      appointment: { date: selected, time: newValue },
    } as EmbeddedUser);
    setSlot(newValue);
  }

  return (
    <Stack
      width={1}
      height={1}
      direction="column"
      alignItems="center"
      padding={theme.spacing.xl}
      spacing={theme.spacing.md}
    >
      <Stack direction="column" alignItems="center">
        <Custom.Typography variant="h2" align="center">
          {t.message.embedded.appointment.title}
        </Custom.Typography>
        <Custom.Typography variant="h5" align="center">
          {t.message.embedded.appointment.subtitle}
        </Custom.Typography>
      </Stack>
      <Stack direction="column" justifyContent="center" height={1} width={1}>
        <Grid container spacing={theme.spacing.md}>
          <Grid item md={6} xs={12}>
            <Stack alignItems="center">
              <Datetime.Calendar min={new Date()} onSelect={handleSelectDate} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={theme.spacing.md} position="relative">
              <Custom.Typography variant="h3">
                {selected.toLocaleDateString(locale, Constants.DATE_FORMAT)}
              </Custom.Typography>
              {slots.length > 0 ? (
                <Controller.Chip
                  sx={{ pr: theme.spacing.md }}
                  wrap={true}
                  items={slots}
                  onSelect={handleSelectSlot}
                  sxItem={{
                    backgroundColor: theme.palette.background.color,
                  }}
                />
              ) : (
                <Progress.NoRecord
                  height={250}
                  message={t.message.placeholder.no_time_slot_available}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack width={1}>
        <Custom.Button
          disabled={!isValid}
          sx={{ width: "auto", ml: "auto" }}
          onClick={handleMakeAppointmentClick}
        >
          {t.action.submit}
        </Custom.Button>
      </Stack>
    </Stack>
  );
};
