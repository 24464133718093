import { ListItemText, Menu as MUIMenu, MenuItem } from "@mui/material";
import { useApp, useMap } from "contexts";
import { ISavedList } from "interfaces";

// icons
import { AddRounded } from "@mui/icons-material";

type Props = {
  onClose: () => void;
  onChange: (id: string) => void;
  onCreate?: () => void;
  anchorEl: HTMLElement | null;
};

export const List = ({ anchorEl = null, onClose, ...props }: Props) => {
  const { t } = useApp();
  const { savedLists } = useMap();

  function handleCreateClick() {
    onClose();
    props.onCreate && props.onCreate();
  }

  function handleChangeClick(id: string) {
    onClose();
    props.onChange(id);
  }

  return (
    <MUIMenu
      open={!!anchorEl}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {props.onCreate && (
        <MenuItem onClick={handleCreateClick}>
          {t.menu.list.create}
          <AddRounded />
        </MenuItem>
      )}
      {!props.onCreate && savedLists.length === 0 && (
        <MenuItem disabled>{t.message.placeholder.no_item}</MenuItem>
      )}
      {savedLists.map((item: ISavedList) => {
        return (
          <MenuItem key={item.id} onClick={() => handleChangeClick(item.id)}>
            {item.name}
          </MenuItem>
        );
      })}
    </MUIMenu>
  );
};
