import { useApp, useTheme } from "contexts";
import { Grid, Stack } from "@mui/material";
import { Custom } from "components";
import { Conversions } from "helpers";
import { SolarSystem } from "types";
import { Constants } from "utils";

type Props = {
  overview: SolarSystem;
};

export const Design = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  return (
    <Stack
      direction="column"
      alignItems="center"
      p={theme.spacing.sm}
      justifyContent="center"
      spacing={theme.spacing.md}
    >
      <Custom.Typography variant="h5" weight="bold" align="center">
        DETAILED INFORMATION
      </Custom.Typography>
      <Grid container py={theme.spacing.md}>
        <Grid item xs={6}>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={theme.spacing.xxxl}
          >
            <Stack
              direction="column"
              alignItems="center"
              spacing={theme.spacing.sm}
              justifyContent="space-between"
            >
              <Custom.Typography variant="h5">
                {t.label.system_size}
              </Custom.Typography>
              <Custom.Typography variant="h2" color={theme.color.accent.color}>
                {Conversions.toUnit(Number(props.overview.systemSize), "kW")}
              </Custom.Typography>
            </Stack>
            <Stack
              direction="column"
              alignItems="center"
              spacing={theme.spacing.sm}
              justifyContent="space-between"
            >
              <Custom.Typography variant="h5">
                {t.label.num_panels}
              </Custom.Typography>
              <Custom.Typography variant="h2" color={theme.color.accent.color}>
                {props.overview.numSelectedPanels}/
                {props.overview.numTotalPanels}
              </Custom.Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={theme.spacing.xxxl}
          >
            <Stack
              direction="column"
              alignItems="center"
              spacing={theme.spacing.sm}
              justifyContent="space-between"
            >
              <Custom.Typography variant="h5">
                {t.label.yearly_production}
              </Custom.Typography>
              <Custom.Typography variant="h2" color={theme.color.accent.color}>
                {Conversions.toUnit(
                  Number(props.overview.yearlyProduction),
                  "kWh"
                )}
              </Custom.Typography>
            </Stack>
            <Stack
              direction="column"
              alignItems="center"
              spacing={theme.spacing.sm}
              justifyContent="space-between"
            >
              <Custom.Typography variant="h5">
                {t.label.offset}
              </Custom.Typography>
              <Custom.Typography variant="h2" color={theme.color.accent.color}>
                {Conversions.toUnit(
                  props.overview.offset,
                  Constants.UNIT.PERCENT
                )}
              </Custom.Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Custom.Typography variant="h6">
        *These figures are provided as estimates only, all calculations must be
        verified.
      </Custom.Typography>
    </Stack>
  );
};
