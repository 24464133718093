import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useApp } from "contexts";
import { IMarker } from "interfaces";
import { Conversions } from "helpers";

// icons
import {
  BoltRounded,
  BusinessRounded,
  CalendarMonthRounded,
  ContentCopyRounded,
  ElectricMeterRounded,
  RoofingRounded,
} from "@mui/icons-material";

type Props = {
  marker: IMarker;
};

export const HouseInfo = ({ marker }: Props) => {
  const { t } = useApp();

  function handleCopyClipboardClick(value: string) {
    navigator.clipboard.writeText(value);
  }

  return (
    <List sx={{ width: 1 }}>
      <ListItemButton onClick={() => handleCopyClipboardClick(marker.roofType)}>
        <ListItemIcon>
          <RoofingRounded />
        </ListItemIcon>
        <ListItemText
          primary={t.label.roof_type}
          secondary={marker.roofType ?? t.placeholder.no_value}
        />
        <ContentCopyRounded />
      </ListItemButton>

      <ListItemButton onClick={() => handleCopyClipboardClick(marker.roofAge)}>
        <ListItemIcon>
          <CalendarMonthRounded />
        </ListItemIcon>
        <ListItemText
          primary={t.label.roof_age}
          secondary={marker.roofAge ?? t.placeholder.no_value}
        />
        <ContentCopyRounded />
      </ListItemButton>

      <ListItemButton
        onClick={() => handleCopyClipboardClick(String(marker.average_bill))}
      >
        <ListItemIcon>
          <ElectricMeterRounded />
        </ListItemIcon>
        <ListItemText
          primary={t.label.average_bill}
          secondary={
            Conversions.toMoney(marker.average_bill) ?? t.placeholder.no_value
          }
        />
        <ContentCopyRounded />
      </ListItemButton>

      <ListItemButton
        onClick={() => handleCopyClipboardClick(marker.utility_company)}
      >
        <ListItemIcon>
          <BusinessRounded />
        </ListItemIcon>
        <ListItemText
          primary={t.label.utility_company}
          secondary={marker.utility_company ?? t.placeholder.no_value}
        />
        <ContentCopyRounded />
      </ListItemButton>

      <ListItemButton
        onClick={() =>
          handleCopyClipboardClick(String(marker.electricity_usage))
        }
      >
        <ListItemIcon>
          <BoltRounded />
        </ListItemIcon>
        <ListItemText
          primary={t.label.electricity_usage}
          secondary={marker.electricity_usage ?? t.placeholder.no_value}
        />
        <ContentCopyRounded />
      </ListItemButton>
    </List>
  );
};
