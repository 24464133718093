import en from "assets/locales/en.json";
import { Enums } from "utils";

export const LOCALE = {
  EN: "EN"
};

export const LOCALE_STRINGS = {
  [LOCALE.EN]: en
};

export const COUNTRY_CONSTANTS = {
  [LOCALE.EN]: {
    phoneCode: "+1"
  }
};

export const DATE_FORMAT = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
} as any;

export const TIME_FORMAT = {
  hour: "2-digit",
  minute: "2-digit",
} as any;

export const INPUT_MASK = {
  DATE: "9999-99-99",
  PHONE: "(999) 999-9999"
}

export const STRING_FORMAT = {
  DATE: "yyyy-MM-dd"
}

export const CURRENCY = {
  USD: "USD"
}

export const UNIT = {
  KWH: "kilowatt-hour",
  PERCENT: "percent"
}

export const TEXTAREA = {
  MAX: 5,
  MIN: 3
};

export const KEY_CODE = {
  ENTER: "Enter",
  TAB: "Tab",
}

export const MAX_INTEGER = 2147483647;
export const MIN_DATE = "1900-01-01";

export const DEFAULT_SCHEDULE = {
  [Enums.EnumDayOfWeek.Sunday]: { start: 0, end: 0 },
  [Enums.EnumDayOfWeek.Monday]: { start: 9, end: 16 },
  [Enums.EnumDayOfWeek.Tuesday]: { start: 9, end: 16 },
  [Enums.EnumDayOfWeek.Wednesday]: { start: 9, end: 16 },
  [Enums.EnumDayOfWeek.Thursday]: { start: 9, end: 16 },
  [Enums.EnumDayOfWeek.Friday]: { start: 9, end: 16 },
  [Enums.EnumDayOfWeek.Saturday]: { start: 0, end: 0 },
};

export const GOOGLE_MAPS = {
  API_KEY: String(process.env.REACT_APP_GOOGLE_API_KEY).trim(),
  MIN_ZOOM: 0,
  MAX_ZOOM: 21,
  ZOOM: 21,
  LATITUDE: 37.6921157,
  LONGITUDE: -121.7719721,
}

export const MAPBOX = {
  API_KEY: String(process.env.REACT_APP_MAPBOX_API_KEY).trim(),
  MIN_ZOOM: 0,
  MAX_ZOOM: 21,
  ZOOM: 19,
  LATITUDE: 37.6921157,
  LONGITUDE: -121.7719721,
}

export const AERIALYTIC = {
  API_KEY: String(process.env.REACT_APP_AERIALYTIC_API_KEY).trim(),
  IFRAME_ENDPOINT: `${String(process.env.REACT_APP_AERIALYTIC_IFRAME_ENDPOINT)}/iframe?access-key={{apiKey}}`,
  API_ENDPOINT: `${String(process.env.REACT_APP_AERIALYTIC_API_ENDPOINT).trim()}/api`,
  LEAD_ID: "28bdb49f-48da-4613-827b-e180636a6097",
  PROPOSAL_ID: "7eef08b4-7913-45a5-84f0-fca43f148a11",
  USAGE: 7200,
  LAT: 37.69239462768057,
  LNG: -121.76966039425304
}

export const IMAGE_ENDPOINT = String(process.env.REACT_APP_IMAGE_ENDPOINT).trim();

export const GOOGLE = {
  API_KEY: String(process.env.REACT_APP_GOOGLE_API_KEY).trim(),
  CLIENT_ID: String(process.env.REACT_APP_GOOGLE_CLIENT_ID).trim(),
  SECRET_KEY: String(process.env.REACT_APP_GOOGLE_SECRET_KEY).trim(),
  REFRESH_TOKEN: String(process.env.REACT_APP_GOOGLE_REFRESH_TOKEN).trim(),
  AUTH: "https://accounts.google.com/o/oauth2/auth?client_id={{clientId}}&redirect_uri={{redirectUri}}&response_type=code&scope={{scope}}&prompt=consent&access_type=offline",
  SCOPE: "https://www.googleapis.com/auth/calendar",
  AUTH_ACCESS_TOKEN: "https://oauth2.googleapis.com/token?client_id={{clientId}}&client_secret={{secret}}&refresh_token={{refreshToken}}&grant_type=refresh_token",
  CALENDAR_LIST: "https://www.googleapis.com/calendar/v3/users/me/calendarList",
  CALENDAR_INFO: "https://www.googleapis.com/calendar/v3/calendars/{{calendarId}}/acl",
  CALENDAR_INSERT: "https://www.googleapis.com/calendar/v3/calendars",
  CALENDAR_DELETE: "https://www.googleapis.com/calendar/v3/calendars/{{calendarId}}",
  CALENDAR_EVENT_INSERT: "https://www.googleapis.com/calendar/v3/calendars/{{calendarId}}/events",
  CALENDAR_EVENT_MANAGE: "https://www.googleapis.com/calendar/v3/calendars/{{calendarId}}/events/{{eventId}}",
  CALENDAR_FREE_BUSY: "https://www.googleapis.com/calendar/v3/freeBusy"
}

export const REFRESH_RATE = {
  ALERT: 5000,
  OVERLAP_MESSAGE: 3000,
  TRANSITION_MESSAGE: 500,
  PLACEHOLDER_THRESHOLD: 10000,
}

export const DEFAULT_CONFIGURATION = {
  appointment: {
    start: 8,
    end: 18,
    length: 30
  },
  theme: {},
  settings: {
    finance: false,
    overview: true,
    design: false,
    coverage: []
  }
};