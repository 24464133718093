import { useEffect, useState } from "react";
import { useApp, useStep, useTheme } from "contexts";
import { Grid, Grow, Stack } from "@mui/material";
import { Custom, Embedded, Progress, Tab } from "components";
import { Constants, Enums } from "utils";
import { Combines } from "helpers";
import { EmbeddedUser, SolarSavings, SolarSystem } from "types";

type Props = {
  featureFlags: string[];
};

export const Overview = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const {
    goTo,
    information,
    isBackClicked,
    setBackClicked,
    setInformation,
    setNextDisabled,
    user,
  } = useStep();

  const [hasLoaded, setLoaded] = useState<boolean>(false);
  const [financeData, setFinanceData] = useState<SolarSavings>(
    {} as SolarSavings
  );
  const [overviewData, setOverviewData] = useState<SolarSystem>(
    {} as SolarSystem
  );

  useEffect(() => {
    if (isBackClicked) goTo(Enums.EnumEmbeddedStep.Quote);
  }, [goTo, isBackClicked, setBackClicked]);

  useEffect(() => setNextDisabled(true), []);

  useEffect(() => {
    if (hasLoaded) {
      setNextDisabled(false);
    }
  }, [hasLoaded]);

  let features = props.featureFlags;
  if (features.length === 0) {
    features = ["overview"];
  }
  
  const items = [
    {
      key: "overview",
      label: t.title.overview,
      children: <Tab.Overview information={information}  overview={overviewData} finance={financeData} />,
    } as Custom.TabProps,
    {
      key: "finance",
      label: t.title.finance,
      children: <Tab.Finance information={information} finance={financeData} />,
    } as Custom.TabProps,
    {
      key: "design",
      label: t.title.design,
      children: <Tab.Design overview={overviewData} />,
    } as Custom.TabProps,
  ].filter((item: Custom.TabProps) => user.configuration.settings[item.key] && features.includes(item.key));

  function handleError() {
    // TODO : it should be implemented to handle error
  }

  function handleLoad() {
    setLoaded(true);
  }

  function handleGenerate(param: object) {
    setInformation({ design: { ...param } } as EmbeddedUser);
  }

  function handleProductionUpdate(data: SolarSystem) {
    setOverviewData(data);
  }

  function handleProposalCostsUpdate(data: SolarSavings) {
    setFinanceData(data);
  }

  return (
    <Stack
      height={1}
      direction="column"
      position="relative"
      gap={theme.spacing.xl}
      padding={theme.spacing.xl}
    >
      {hasLoaded && (
        <Custom.Typography variant="h3" align="center">
          {Combines.interpolate(t.message.embedded.overview.title, {
            address: information.map.address,
          })}
        </Custom.Typography>
      )}
      <Grid container spacing={theme.spacing.xl} height={1}>
        {hasLoaded && (
          <Grow in={hasLoaded}>
            <Grid item xs={12} md={6}>
              <Custom.Tabs items={items} />
            </Grid>
          </Grow>
        )}
        <Grid item xs={12} md={hasLoaded ? 6 : 12}>
          <Stack direction="column" gap={theme.spacing.md} height={1}>
            <Embedded.Iframe
              onReady={handleLoad}
              onError={handleError}
              onGenerate={handleGenerate}
              leadId={information.design?.leadId}
              coordinate={information.map.coordinate}
              proposalId={information.design?.proposalId}
              onProductionUpdate={handleProductionUpdate}
              onProposalCostsUpdate={handleProposalCostsUpdate}
            />
            {hasLoaded && (
              <Custom.Typography variant="h6">
                {t.message.embedded.overview.description}
              </Custom.Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
