import { useEffect, useState } from "react";
import { useApp, useStep, useTheme } from "contexts";
import { Stack } from "@mui/material";
import { Custom, Common, Google, Vendor } from "components";
import { Enums, Geocoder } from "utils";
import { Validations } from "helpers";
import { Coordinate, EmbeddedUser } from "types";
import { GeocodeFeature } from "@mapbox/mapbox-sdk/services/geocoding";
import { USStates } from "assets/data";
import axios from 'axios';
import { AERIALYTIC } from "utils/constants";
import { useLocation } from "react-router-dom";

type Props = {};

export const Map = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const {
    goTo,
    isBackClicked,
    isNextClicked,
    setBackClicked,
    setFeedback,
    setNextClicked,
    setNextDisabled,
    setNextStep,
    setBackDisabled,
    information,
    setInformation,
    user,
  } = useStep();

  const location = useLocation();
  const [step, setStep] = useState<Enums.EnumEmbeddedStepMap>(
    Enums.EnumEmbeddedStepMap.Search
  );
  const [coordinate, setCoordinate] = useState<Coordinate>(
    information.map?.coordinate
  );
  const [searchResult, setSearchResult] = useState<GeocodeFeature[]>([]);

  const isValid: boolean =
    step === Enums.EnumEmbeddedStepMap.Map &&
    information.map &&
    Validations.hasValue(information.map.address);

  useEffect(() => {
    if (isNextClicked && information.map) {
      const handleNextClick = async () => {
        console.log('handleNextClick')
        if (isValid) {
          // TODO : should call an API to check whether this address is available on nearmap
          goTo(Enums.EnumEmbeddedStep.Details);
        } else if (information.map.coordinate) {
          const coverage = await hasCoverage(information.map.coordinate); 
  
          if (coverage) {
            setStep(Enums.EnumEmbeddedStepMap.Map);
          } else {
            setBackDisabled(true);
            goTo(Enums.EnumEmbeddedStep.NoCoverage);
          }
        }
      };
  
      handleNextClick().finally(() => setNextClicked(false));
    } else {
      setNextClicked(false);
    }
  }, [goTo, information.map, isNextClicked, setNextClicked, step, isValid]);
    

  useEffect(() => {
    if (isBackClicked) {
      if (step === Enums.EnumEmbeddedStepMap.Map) {
        setStep(Enums.EnumEmbeddedStepMap.Search);
      } else {
        goTo(Enums.EnumEmbeddedStep.Survey);
      }
      setBackClicked(false);
    }
  }, [goTo, isBackClicked, setBackClicked, step]);

  useEffect(() => {
    if (!isBackClicked && information.map) {
      setStep(Enums.EnumEmbeddedStepMap.Map);
    }
  }, []);

  useEffect(() => setNextDisabled(!isValid), [isValid, setNextDisabled]);

  async function handleClick(coordinate: Coordinate) {
    const response = await Geocoder.fetchAddress(
      coordinate.lat,
      coordinate.lng
    );
    setInformation({
      map: { coordinate, address: response },
    } as EmbeddedUser);
    setCoordinate(coordinate);
    setNextClicked(true);
  }

  function handleChange(coordinate: Coordinate, address: string) {
    setInformation({ map: { coordinate, address } } as EmbeddedUser);
  }

  function extractStreetAddress(address: string): string | null {
    // Define a regular expression pattern to match the street number and name.
    // The street number and name could be at the beginning or in the middle of the address.
    const streetPattern = /(\d+\s[a-zA-Z0-9\s]+)/;

    // Try to match the pattern in the given address string.
    const match = address.match(streetPattern);

    // If there's a match, return the extracted street number and name part.
    if (match && match.length > 1) {
      return match[1].trim();
    }

    // If no match is found, return null to indicate failure.
    return t.message.not_found;
  }

  async function hasCoverage(coordinates: Coordinate): Promise<boolean> {
    const queryParams = new URLSearchParams(location.search);
    let apiKey = queryParams.get("apiKey");
    try {
      const response = await axios.post(
        `${AERIALYTIC.API_ENDPOINT}/iframe/coverage/`,
        {
          coords: `${coordinates.lat},${coordinates.lng}`,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Api-Key ' + apiKey,
          },
        }
      );
      
      return response.data as boolean; 
    } catch (error) {
      console.error("Error checking coverage:", error);
      return false;
    }
  }

  return (
    <Stack
      height={1}
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      {step === Enums.EnumEmbeddedStepMap.Search && (
        <Stack
          width={1}
          maxWidth={400}
          margin="0 auto"
          gap={theme.spacing.xl}
          padding={theme.spacing.xl}
        >
          <Custom.Typography variant="h3" align="center">
            {t.message.embedded.map.search}
          </Custom.Typography>
          <Vendor.Search
            onSearch={setSearchResult}
            type={Enums.EnumMapboxType.Address}
          />
          <Vendor.SearchResult items={searchResult} onClick={handleClick} />
        </Stack>
      )}
      {step === Enums.EnumEmbeddedStepMap.Map && (
        <>
          {information.map.address && (
            <Common.OverlapMessage
              collapsable={false}
              sx={{
                top: theme.spacing.sm * theme.spacing.factor,
                left: theme.spacing.sm * theme.spacing.factor,
              }}
              message={extractStreetAddress(information.map.address) ?? ""}
            />
          )}
          <Common.OverlapMessage
            collapsable={false}
            sx={{
              bottom: theme.spacing.sm * theme.spacing.factor,
              right: theme.spacing.sm * theme.spacing.factor,
            }}
            message={t.message.embedded.map.marker}
          />
          <Google.Map onChange={handleChange} coordinate={coordinate} />
        </>
      )}
    </Stack>
  );
};
