import { EmbeddedData, IEmbeddedData } from "data";
import { ApiResponse, EmbeddedUser } from "types";
import { IEmbeddedUser } from "interfaces";
import { ICompany, IRoofType } from "interfaces/embedded";

export interface IEmbeddedService {
  findCompany(token: string): Promise<ApiResponse<ICompany>>;
  findRoofTypes(token: string): Promise<ApiResponse<IRoofType[]>>;
  findUser(userId: string): Promise<ApiResponse<IEmbeddedUser>>;
  insertLead(data: EmbeddedUser, token: string): Promise<ApiResponse<boolean>>;
}

export class EmbeddedService implements IEmbeddedService {
  embeddedData: IEmbeddedData;

  constructor() {
    this.embeddedData = new EmbeddedData();
  }


  async findRoofTypes(token: string): Promise<ApiResponse<IRoofType[]>> {
    return await this.embeddedData.findRoofTypes(token);
  }


  /**
   * fetch the customization configs from a company
   * @returns
   */
  async findCompany(token: string): Promise<ApiResponse<ICompany>> {
    return await this.embeddedData.findCompany(token);
  }

  /**
   * fetch the customization configs from an user
   * @returns
   */

  async findUser(userId: string): Promise<ApiResponse<IEmbeddedUser>> {
    return await this.embeddedData.findUser(userId);
  }

  /**
   * insert the data generated on the lead gen app
   * @returns
   */

  async insertLead(data: EmbeddedUser, token: string): Promise<ApiResponse<boolean>> {
    return await this.embeddedData.insertLead(data, token);
  }
}