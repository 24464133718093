import React, { useEffect, useState } from "react";
import { IconButton, Stack, Box } from "@mui/material";
import { useApp, useService, useStep, useTheme } from "contexts";
import { Controller, Custom, Embedded } from "components";
import { Constants, Enums } from "utils";
import { Combines, Sanitizes, Validations } from "helpers";
import { EmbeddedUser } from "types";

// icons
import { HelpRounded } from "@mui/icons-material";

type Props = {};

const initialValues = {
  type: "",
  age: "",
  bill: "",
  company: "",
};

export const Details = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const {
    user,
    information,
    goTo,
    isBackClicked,
    isNextClicked,
    setNextClicked,
    setNextDisabled,
    setInformation,
    roofs
  } = useStep();
  const { embedded } = useService();

  const [expanded, setExpanded] = useState<string | false>(false);
  const [isRoofTypeInfoOpen, setRoofTypeInfoOpen] = useState(false);
  const [details, setDetails] = useState(information.details ?? initialValues);

  const openRoofTypeInfo = () => setRoofTypeInfoOpen(true);
  const closeRoofTypeInfo = () => setRoofTypeInfoOpen(false);

  const isValid: boolean =
    information.details &&
    Validations.hasValue(
      information.details.type,
      information.details.age,
      information.details.bill
    );

  useEffect(() => {
    if (isNextClicked && isValid) {
      goTo(Enums.EnumEmbeddedStep.Info);
    }
    setNextClicked(false);
  }, [isNextClicked]);

  useEffect(() => {
    if (isBackClicked) goTo(Enums.EnumEmbeddedStep.Map);
  }, [isBackClicked]);

  useEffect(() => setNextDisabled(!isValid), [isValid, setNextDisabled]);

  function handleAccordionChange(key: string, expanded: boolean) {
    setExpanded(expanded ? key : false);
  }

  function handleChange(key: string, value: string) {
    setExpanded(false);
    const newValue = {
      ...details,
      [key]: key === "bill" ? Sanitizes.toNumber(value) : value,
    };
    setDetails(newValue);
    setInformation({ details: newValue } as EmbeddedUser);
  }

  return (
    <Stack alignItems="center" justifyContent="center">
      <Stack
        width={600}
        maxWidth={1}
        margin="0 auto"
        p={theme.spacing.lg}
        spacing={theme.spacing.xl}
      >
        <Custom.Typography variant="h2" align="center">
          {t.title.details}
        </Custom.Typography>

        {/* begin roof type */}
        <Stack spacing={theme.spacing.sm}>
          <Stack direction="row" alignItems="center">
            <Custom.Typography variant="h4" weight="normal">
              {t.message.embedded.details.roof_type}
            </Custom.Typography>
            <IconButton
              onClick={openRoofTypeInfo}
              sx={{ color: theme.palette.font.color }}
            >
              <HelpRounded />
            </IconButton>
          </Stack>
          <Stack>
            <Custom.Accordion
              header={
                <Custom.Typography>
                  {roofs.find((item) => item.id === details.type)?.name || t.placeholder.choose_one}
                </Custom.Typography>
              }
              expanded={expanded === "roofType"}
              onChange={(_, expanded) =>
                handleAccordionChange("roofType", expanded)
              }
            >
              <Controller.Radio
                name="roofType"
                value={details.type}
                onChange={(_: any, value: string) => {
                    console.log("value:", value)
                    handleChange("type", value)
                  }
                }
                
                items={roofs.map((item) => ({
                  label: item.name,
                  value: item.id,
                  disabled: item.serviceable === false
                }))
                }
              />
            </Custom.Accordion>
          </Stack>
        </Stack>
        {/* end roof type */}

        {/* begin roof age */}
        <Stack spacing={theme.spacing.sm}>
          <Stack direction="column">
            <Custom.Typography variant="h4" weight="normal">
              {t.message.embedded.details.roof_age}
            </Custom.Typography>
          </Stack>
          <Stack direction="column">
            <Custom.Accordion
              expanded={expanded === "roofAge"}
              header={
                <Custom.Typography>
                  {details.age
                    ? Combines.interpolate(t.placeholder.x_year, {
                        count: details.age,
                      })
                    : t.placeholder.choose_one}
                </Custom.Typography>
              }
              onChange={(_, expanded) =>
                handleAccordionChange("roofAge", expanded)
              }
            >
              <Controller.Radio
                name="roofAge"
                value={details.age}
                onChange={(_: any, value: string) => handleChange("age", value)}
                // TODO : make it dynamic
                items={[
                  { label: "0—5", disabled: false },
                  { label: "5—10", disabled: false },
                  { label: "10—15", disabled: false },
                  { label: "15+", disabled: false },
                ]}
              />
            </Custom.Accordion>
          </Stack>
        </Stack>
        {/* end roof age */}

        {/* begin utility bill */}
        <Stack spacing={theme.spacing.sm}>
          <Custom.Typography variant="h4" weight="normal">
            {t.message.embedded.details.average_bill}
          </Custom.Typography>
          <Custom.TextField
            name="electricBill"
            value={details.bill}
            placeholder={t.placeholder.number_only}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange("bill", event.target.value.replace(/^[0]\d*$/, ""))
            }
            InputProps={{
              startAdornment: (
                <Box mr={theme.spacing.sm}>
                  <Custom.Typography variant="h6">
                    {Constants.CURRENCY.USD}
                  </Custom.Typography>
                </Box>
              ),
            }}
          />
        </Stack>
        {/* end utility bill */}

        {/* begin utility company */}
        {/* <Stack spacing={theme.spacing.sm}>
          <Custom.Typography variant="h4" weight="normal">
            {t.message.embedded.details.utility_company}
          </Custom.Typography>
          <Controller.Radio
            inline={true}
            name="utilityCompany"
            value={details.company}
            onChange={(_: any, value: string) => handleChange("company", value)}
            // TODO : it should be either included in the configuration or retrived from some API
            items={["Company A", "Company B", "Company C"].map(
              (item: string) => ({
                label: item,
              })
            )}
          />
        </Stack> */}
      </Stack>
      {/* end utility company */}

      {/* begin roof type modal */}
      <Embedded.RoofTypeInfo
        open={isRoofTypeInfoOpen}
        onClose={closeRoofTypeInfo}
      />
      {/* end roof type modal */}
    </Stack>
  );
};
