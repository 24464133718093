import React from "react";
import { Grid, Box, Stack } from "@mui/material";
import {
  AsphaltShingles,
  Metal,
  FlatRoof,
  ClayTile,
  CementTile,
  OtherRoof,
} from "assets/images";
import { useTheme } from "contexts";
import { Custom } from "components";

type RoofType = {
  type: string;
  image: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const roofTypes: RoofType[] = [
  {
    type: "Asphalt Shingles",
    image: AsphaltShingles,
  },
  {
    type: "Metal",
    image: Metal,
  },
  {
    type: "Flat Roof",
    image: FlatRoof,
  },
  {
    type: "Clay Tile",
    image: ClayTile,
  },
  {
    type: "Cement Tile",
    image: CementTile,
  },
  {
    type: "Other",
    image: OtherRoof,
  },
];

export const RoofTypeInfo = (props: Props) => {
  const { theme } = useTheme();

  return (
    <Custom.Modal title="Roof Types" open={props.open} onClose={props.onClose}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={theme.spacing.md}
      >
        {roofTypes.map((roofType) => (
          <Grid item md={6} xs={12} key={roofType.type}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={theme.spacing.md}
            >
              <Box
                height={80}
                width={100}
                component="img"
                src={roofType.image}
                alt={roofType.type}
                borderRadius={theme.border.radius}
              />
              <Custom.Typography variant="h4">
                {roofType.type}
              </Custom.Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Custom.Modal>
  );
};
