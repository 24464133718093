import { useApp, useTheme } from "contexts";
import { Stack } from "@mui/material";
import { Custom, Embedded } from "components";
import { Auxiliars, Conversions } from "helpers";
import { EmbeddedUser, SolarSavings, SolarSystem } from "types";
import { Constants, Enums } from "utils";
import { useEffect } from "react";

type Props = {
  overview: SolarSystem;
  finance: SolarSavings;
  information: EmbeddedUser;
};

export const Overview = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const textColor = Auxiliars.getContrast(theme.color.accent.color);

  const lifetimeSavings: number = props.finance && props.finance.estimated25YearSavings ? props.finance.estimated25YearSavings : 0;
  const yearlySavings: number = props.finance && props.finance.estimatedYearlySavings ? props.finance.estimatedYearlySavings : 0;

  if (lifetimeSavings === 0 || yearlySavings === 0) {
    return (
      <Stack
        direction="column"
        p={theme.spacing.sm}
        justifyContent="center"
        spacing={theme.spacing.md}
      >
        <Custom.Typography variant="h5" weight="bold" align="center">
          PROJECT SUMMARY
        </Custom.Typography>
        <Stack paddingTop={15}>
          <Embedded.Progress status={Enums.EnumIframeStatus.Proposal} generated={false} />
        </Stack>
      </Stack>
    )
  }
  
  return (
    <Stack
      direction="column"
      alignItems="center"
      p={theme.spacing.sm}
      justifyContent="center"
      spacing={theme.spacing.md}
    >
      <Custom.Typography variant="h5" weight="bold" align="center">
        PROJECT SUMMARY
      </Custom.Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={theme.spacing.xxxl}
      >
        <Stack
          direction="column"
          alignItems="center"
          spacing={theme.spacing.sm}
          justifyContent="space-between"
        >
          <Custom.Typography variant="h6">
            {t.label.system_size}
          </Custom.Typography>
          <Custom.Typography variant="h4" color={theme.color.accent.color}>
            {Conversions.toUnit(Number(props.overview.systemSize), "kW")}
          </Custom.Typography>
        </Stack>
        <Stack
          direction="column"
          alignItems="center"
          spacing={theme.spacing.sm}
          justifyContent="space-between"
        >
          <Custom.Typography variant="h6">
            {t.label.yearly_production}
          </Custom.Typography>
          <Custom.Typography variant="h4" color={theme.color.accent.color}>
            {Conversions.toUnit(Number(props.overview.yearlyProduction), "kWh")}
          </Custom.Typography>
        </Stack>
        <Stack
          direction="column"
          alignItems="center"
          spacing={theme.spacing.sm}
          justifyContent="space-between"
        >
          <Custom.Typography variant="h6">{t.label.offset}</Custom.Typography>
          <Custom.Typography variant="h4" color={theme.color.accent.color}>
            {Conversions.toUnit(props.overview.offset, Constants.UNIT.PERCENT)}
          </Custom.Typography>
        </Stack>
      </Stack>
      <Stack
        width={1}
        direction="column"
        alignItems="center"
        p={theme.spacing.md}
        spacing={theme.spacing.sm}
        borderRadius={theme.border.radius}
        bgcolor={theme.color.accent.color}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <Custom.Typography variant="h6" color={textColor}>
            Estimated 25 year Savings
          </Custom.Typography>
          <Custom.Typography variant="h2" color={textColor}>
            {Conversions.toMoney(lifetimeSavings)}
          </Custom.Typography>
        </Stack>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <Custom.Typography variant="h6" color={textColor}>
            Estimated yearly Savings
          </Custom.Typography>
          <Custom.Typography variant="h2" color={textColor}>
            {Conversions.toMoney(yearlySavings)}
          </Custom.Typography>
        </Stack>
        <Custom.Typography as="small" color={textColor}>
          Both value calculated based on 100% offset
        </Custom.Typography>
      </Stack>
      <Custom.Typography variant="h6">
        *These figures are provided as estimates only, all calculations must be
        verified.
      </Custom.Typography>
    </Stack>
  );
};
