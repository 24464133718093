import { ListItemText, Menu as MUIMenu, MenuItem } from "@mui/material";
import { useApp, useMap } from "contexts";
import { IActivityStatus } from "interfaces";

type Props = {
  onClose: () => void;
  onChange: (id: string) => void;
  anchorEl: HTMLElement | null;
};

export const Status = ({ anchorEl = null, onClose, ...props }: Props) => {
  const { t } = useApp();
  const { statuses } = useMap();

  function handleStatusChange(id: string) {
    onClose();
    props.onChange(id);
  }

  return (
    <MUIMenu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {statuses.length === 0 && (
        <MenuItem disabled>{t.message.placeholder.no_item}</MenuItem>
      )}
      {statuses.map((item: IActivityStatus) => {
        return (
          <MenuItem key={item.id} onClick={() => handleStatusChange(item.id)}>
            {item.name}
          </MenuItem>
        );
      })}
    </MUIMenu>
  );
};
