import "./index.scss";

import ReactDOM from "react-dom/client";
import Routers from "router";
import { AppProvider, ThemeProvider } from "contexts";
import { Light } from "themes";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// Initialize Sentry if in production mode
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release: 'YOUR_PROJECT_NAME@' + process.env.VERSION,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AppProvider initLocale={"EN"}>
    <ThemeProvider initTheme={Light}>
      <Routers />
    </ThemeProvider>
  </AppProvider>
);
