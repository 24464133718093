import { Checks, Conversions } from "helpers";
import { GoogleCalendarEvent } from "types";
import { Apis, Constants, Store } from "utils";

export interface IGoogleData {
  refreshToken(): Promise<boolean>;
  all(): Promise<any[]>;
  info(calendarId: string): Promise<any | undefined>;
  create(calendarName: string, timeZone: string): Promise<string | "">;
  share(calendarId: string, email: string): Promise<boolean>;
  delete(calendarId: string): Promise<boolean>;
  find(calendarId: string, eventId: string): Promise<any | undefined>;
  insert(calendarId: string, date: Date | null, slot: string | null, duration: number, event?: GoogleCalendarEvent): Promise<string | undefined>;
  update(calendarId: string, eventId: string, event: GoogleCalendarEvent): Promise<boolean | undefined>;
  remove(calendarId: string, eventId: string): Promise<boolean | undefined>;
  availability(calendarId: string, date: Date | null, duration: number, startTime: number, endTime: number): Promise<string[]>;
}

export class GoogleData implements IGoogleData {

  /**
   * verify if the access token is still valid
   * @returns {boolean} true if valid
   */

  get isValid(): boolean {
    const { expires_at } = Store.sessionStorage.get();
    return expires_at && Date.now() <= expires_at
  }

  /**
   * set the acccess token into the session
   * @param {TokenResponse} google response
   */

  set sessionToken({ access_token, expires_in }: any) {
    Store.sessionStorage.set({ access_token, expires_at: Date.now() + expires_in * 1000 });
  }

  /**
   * get the acccess token from the session
   * @returns {string} access token
   */

  get sessionToken(): string {
    const { access_token } = Store.sessionStorage.get();
    return access_token;
  }

  async refreshToken() {
    try {
      const response = await Apis.oauth(Constants.GOOGLE.AUTH_ACCESS_TOKEN, { clientId: Constants.GOOGLE.CLIENT_ID, secret: Constants.GOOGLE.SECRET_KEY, refreshToken: Constants.GOOGLE.REFRESH_TOKEN }).post('');
      if (response.data) {
        this.sessionToken = response.data
        return true;
      }
    } catch (err) {
      this.#handleError(err);
    }
    return false;
  }

  async all() {
    try {
      await this.#handleRequest();
      const response = await Apis.calendar(Constants.GOOGLE.CALENDAR_LIST, this.sessionToken).get('');
      return response.data ? response.data.items : [];
    } catch (err) {
      this.#handleError(err);
    }
  }

  async info(calendarId: string) {
    try {
      await this.#handleRequest();
      const response = await Apis.calendar(Constants.GOOGLE.CALENDAR_INFO, this.sessionToken, { calendarId }).get('');
      if (response.data && response.data.items && response.data.items.length > 0) return response.data.items[1].scope.value;
    } catch (err) {
      this.#handleError(err);
    }
  }

  async create(calendarName: string, timeZone: string) {
    try {
      await this.#handleRequest();
      const response = await Apis.calendar(Constants.GOOGLE.CALENDAR_INSERT, this.sessionToken).post('', { summary: calendarName, timeZone });
      if (response.data) return response.data.id;
    } catch (err) {
      this.#handleError(err);
    }
    return ""
  }

  async share(calendarId: string, email: string) {
    try {
      await this.#handleRequest();
      await Apis.calendar(Constants.GOOGLE.CALENDAR_INFO, this.sessionToken, { calendarId }).post('', {
        scope: {
          type: "user",
          value: email,
        },
        role: "owner"
      });
      return true;
    } catch (err) {
      this.#handleError(err);
    }
    return false;
  }

  async delete(calendarId: string) {
    try {
      await this.#handleRequest();
      await Apis.calendar(Constants.GOOGLE.CALENDAR_DELETE, this.sessionToken, { calendarId }).delete('');
      return true;
    } catch (err) {
      this.#handleError(err);
    }
    return false;
  }

  async find(calendarId: string, eventId: string) {
    try {
      await this.#handleRequest();
      const response = await Apis.calendar(Constants.GOOGLE.CALENDAR_EVENT_MANAGE, this.sessionToken, { calendarId, eventId }).get('');
      if (response.data) return response.data;
    } catch (err) {
      this.#handleError(err);
    }
  }

  async insert(calendarId: string, date: Date | null, slot: string | null, duration: number, event: GoogleCalendarEvent = {} as GoogleCalendarEvent): Promise<string | undefined> {
    await this.#handleRequest();

    event.locked = true;
    const { start, end } = Conversions.toDateStartEnd(date, slot, duration);
    event.start = { dateTime: start };
    event.end = { dateTime: end };

    try {
      const response = await Apis.calendar(Constants.GOOGLE.CALENDAR_EVENT_INSERT, this.sessionToken, { calendarId }).post('', event);
      if (response.data) return response.data.id;
    } catch (err) {
      this.#handleError(err);
    }
  }

  async update(calendarId: string, eventId: string, event: GoogleCalendarEvent) {
    try {
      await this.#handleRequest();
      const response = await Apis.calendar(Constants.GOOGLE.CALENDAR_EVENT_MANAGE, this.sessionToken, { calendarId, eventId }).put('', event);
      return response.data ? true : false;
    } catch (err) {
      this.#handleError(err);
    }
  }

  async remove(calendarId: string, eventId: string) {
    try {
      await this.#handleRequest();
      const response = await Apis.calendar(Constants.GOOGLE.CALENDAR_EVENT_MANAGE, this.sessionToken, { calendarId, eventId }).delete('');
      return response.data ? true : false;
    } catch (err) {
      this.#handleError(err);
    }
  }

  async availability(calendarId: string, date: Date | null, duration: number, startTime: number, endTime: number) {
    try {
      if (!date) return [];

      await this.#handleRequest();

      const slots = [];
      const events = await this.#busy(calendarId, date);
      const reduced = this.#reduceArray(events, duration, startTime * 60, endTime * 60);
      const min = Checks.isDateEquals(date, new Date()) ? Conversions.toSeconds(new Date(date.setTime(new Date().getTime()))) / 60 : 0;

      for (let i = startTime * 60; i <= endTime * 60; i += duration) {
        if (i > min) {
          const ampm = Conversions.toTimeAmPmString(i);
          if (!reduced.includes(i) && !reduced.includes(i + duration - 5)) {
            slots.push(ampm);
          }
        }
      }

      return slots;
    } catch (err) {
      this.#handleError(err);
    }

    return [];
  }

  /**
   * fetch the busy slots for a specific date
   * @param {string} calendarId calendar id provided by google calendar api
   * @param {Date} date date where the slots will be fetched
   * @returns {boolean} the list of available slots
   */

  async #busy(calendarId: string, date: Date) {
    try {
      const response = await Apis.calendar(Constants.GOOGLE.CALENDAR_FREE_BUSY, this.sessionToken).post('', {
        timeMin: Conversions.toISOString(date, "00:00:00"),
        timeMax: Conversions.toISOString(date, "23:59:59"),
        items: [
          {
            id: calendarId
          }
        ]
      });
      return response.data ? response.data.calendars[calendarId].busy : null;
    } catch (err) {
      this.#handleError(err);
    }
  }

  /**
   * function to treat the return from the availability request and reduce the values
   * @param {Array} events events on a specific date
   * @return list of busy slots
   */

  #reduceArray(events: any, duration: number, startTime: number, endTime: number) {
    if (!events || (events && events.length === 0)) return [];
    /**
     * only the start time is not considered due to the necessity of allowing the overlap of the end time
     * only the times that overlaps the possible slots (considering the length) are included to enhance performance
     */
    return events.map((item: { start: string, end: string }) => {
      const busy = [];
      let date = new Date(item.start);
      const endDate = new Date(item.end);
      // const timezoneOffset = date.getTimezoneOffset();
      // excludes the end time
      while (date < endDate) {
        // it converts time to string e.g. x timestamp => 10:00:00
        const timeString = Conversions.toTimeString(date.getTime() / 1000);
        // it converts the time string to minutes e.g. 10:00:00 => 600
        const minutes = Conversions.toMinutes(timeString);
        // it verifies if the minutes are multiple of the timespan defined
        if (minutes % 5 === 0) {
          busy.push(minutes);
        }
        date.setMinutes(date.getMinutes() + 1);
      }
      return busy.join();
    }).join();
  }

  /**
   * handle the functions before the main request
   */

  async #handleRequest() {
    if (!this.sessionToken || !this.isValid) {
      await this.refreshToken();
    }
  }

  /**
   * handle possible erros from the API
   * @param {any} err error object from the server
   * @returns {null} nothing
   */

  #handleError(err: any) {
    // console.log(err);
  }
}