import { useApp, useStep, useTheme } from "contexts";
import { Grid } from "@mui/material";
import { useForm } from "hooks";
import { Constants } from "utils";
import { Sanitizes, Validations } from "helpers";
import { Custom } from "components";
import { EmbeddedUser } from "types";

export type QuoteForm = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export const QuoteInitialState: QuoteForm = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

type Props = {
  onUpdate: (values: QuoteForm, isValid: boolean) => void;
};

export const Quote = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const { information, setInformation } = useStep();

  const { onChange, values } = useForm<QuoteForm>(
    information.quote ?? QuoteInitialState,
    handleInitCallback,
    undefined,
    handleUpdateCallback,
    validationCallback
  );

  const isEmailInvalid =
    Validations.hasValue(values.email) && !Validations.isEmail(values.email);
  const isPhoneInvalid =
    Validations.hasValue(values.phone) && !Validations.isPhone(values.phone);

  function validationCallback(values: QuoteForm) {
    return (
      Validations.hasValue(
        values.firstName,
        values.lastName,
        values.email,
        values.phone
      ) &&
      Validations.isEmail(values.email) &&
      Validations.isPhone(values.phone)
    );
  }

  function handleInitCallback(values: QuoteForm, isValid: boolean) {
    updateInformation(values);
    props.onUpdate && props.onUpdate(values, isValid);
  }

  function handleUpdateCallback(
    name: string,
    value: any,
    values: QuoteForm,
    isValid: boolean
  ) {
    updateInformation(values);
    props.onUpdate && props.onUpdate(values, isValid);
  }

  function updateInformation(values: QuoteForm) {
    setInformation({
      quote: { ...values, phone: Sanitizes.toNumber(values.phone) },
    } as EmbeddedUser);
  }

  return (
    <form>
      <Grid container spacing={theme.spacing.sm} justifyContent="center">
        <Grid item xs={12} sm={12} md={4}>
          <Custom.TextField
            name="firstName"
            required={true}
            onChange={onChange}
            label={t.label.first_name}
            value={values.firstName}
            placeholder={t.placeholder.first_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Custom.TextField
            name="lastName"
            required={true}
            onChange={onChange}
            label={t.label.last_name}
            value={values.lastName}
            placeholder={t.placeholder.last_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Custom.TextField
            name="email"
            type="email"
            required={true}
            onChange={onChange}
            label={t.label.email}
            value={values.email}
            placeholder={t.placeholder.email}
            error={isEmailInvalid}
            helperText={
              isEmailInvalid ? t.message.validation.is_email_address : undefined
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Custom.TextField
            name="phone"
            required={true}
            onChange={onChange}
            label={t.label.phone}
            value={values.phone}
            mask={Constants.INPUT_MASK.PHONE}
            placeholder={t.placeholder.phone}
            error={isPhoneInvalid}
            helperText={
              isPhoneInvalid ? t.message.validation.is_phone_number : undefined
            }
          />
        </Grid>
      </Grid>
    </form>
  );
};
