import React, { createContext, useCallback, useState, useContext } from "react";
import { Constants, Enums } from "utils";
import { Auxiliars } from "helpers";
import { EmbeddedUser } from "types";
import { EnumEmbeddedStep } from "utils/enums";
import { IEmbeddedUser } from "interfaces";
import { ICompany, IRoofType } from "interfaces/embedded";

export const StepContext = createContext<{
  user: IEmbeddedUser;
  setUser: (user: IEmbeddedUser) => void;
  company: ICompany;
  setCompany: (company: ICompany) => void;
  isLoaded: boolean;
  setLoaded: (state: boolean) => void;
  currentStep: Enums.EnumEmbeddedStep;
  goTo: (step: Enums.EnumEmbeddedStep) => void;
  nextStep: Enums.EnumEmbeddedStep | null;
  setNextStep: (step: Enums.EnumEmbeddedStep | null) => void;
  isNextDisabled: boolean;
  isBackDisabled: boolean;
  setNextDisabled: (state: boolean) => void;
  setBackDisabled: (state: boolean) => void;
  isNextClicked: boolean;
  isBackClicked: boolean;
  setNextClicked: (state: boolean) => void;
  setBackClicked: (state: boolean) => void;
  information: EmbeddedUser;
  setInformation: (data: EmbeddedUser | null) => void;
  feedback: Enums.EnumEmbeddedStepFeedback;
  setFeedback: (component: Enums.EnumEmbeddedStepFeedback) => void;
  roofs: IRoofType[];
  setRoofs: (roofs: IRoofType[]) => void;
}>({
  user: {} as IEmbeddedUser,
  setUser: () => {},
  company: {} as ICompany,
  setCompany: () => {},
  isLoaded: false,
  setLoaded: () => {},
  currentStep: EnumEmbeddedStep.Survey,
  goTo: () => {},
  nextStep: null,
  setNextStep: () => {},
  isNextDisabled: true,
  isBackDisabled: true,
  setNextDisabled: () => {},
  setBackDisabled: () => {},
  isNextClicked: false,
  isBackClicked: false,
  setNextClicked: () => {},
  setBackClicked: () => {},
  information: {} as EmbeddedUser,
  setInformation: () => {},
  feedback: Enums.EnumEmbeddedStepFeedback.Sorry,
  setFeedback: () => {},
  roofs: [] as IRoofType[], 
  setRoofs: () => {}
});

export const StepProvider = ({
  initInfo,
  initStep,
  children,
}: {
  initInfo: EmbeddedUser;
  initStep: Enums.EnumEmbeddedStep;
  children: React.ReactNode;
}) => {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<Enums.EnumEmbeddedStepFeedback>(
    Enums.EnumEmbeddedStepFeedback.Sorry
  );
  const [user, setUser] = useState<IEmbeddedUser>({
    configuration: Constants.DEFAULT_CONFIGURATION,
  } as unknown as IEmbeddedUser);

  const [company, setCompany] = useState<ICompany>({id: "", name: "", logo: ""});
  const [roofs, setRoofs] = useState<IRoofType[]>([]);
  const [information, setInformation] = useState<EmbeddedUser>(initInfo);

  const [nextStep, setNextStep] = useState<Enums.EnumEmbeddedStep | null>(null);
  const [currentStep, setCurrentStep] =
    useState<Enums.EnumEmbeddedStep>(initStep);
  const [isNextDisabled, setNextDisabled] = useState<boolean>(true);
  const [isBackDisabled, setBackDisabled] = useState<boolean>(false);

  const [isNextClicked, setNextClicked] = useState<boolean>(false);
  const [isBackClicked, setBackClicked] = useState<boolean>(false);

  const goTo = (step: Enums.EnumEmbeddedStep) => {
    setCurrentStep(step);
    setNextClicked(false);
    setBackClicked(false);
    setNextDisabled(true);
    setBackDisabled(false);
  };

  const setInformationCallback = useCallback(
    (newInformation: EmbeddedUser | null) => {
      setInformation((currentInformation: EmbeddedUser) =>
        newInformation === null
          ? ({} as EmbeddedUser)
          : Auxiliars.deepInterpolation(currentInformation, newInformation)
      );
    },
    []
  );

  const setUserCallback = useCallback((newUser: IEmbeddedUser | null) => {
    setUser((currentUser: IEmbeddedUser) =>
      newUser === null
        ? ({} as IEmbeddedUser)
        : Auxiliars.deepInterpolation(currentUser, newUser)
    );
  }, []);

  return (
    <StepContext.Provider
      value={{
        user,
        setUser: setUserCallback,
        company,
        setCompany,
        isLoaded,
        setLoaded,
        goTo,
        currentStep,
        nextStep,
        setNextStep,
        isNextDisabled,
        isBackDisabled,
        setNextDisabled,
        setBackDisabled,
        isNextClicked,
        isBackClicked,
        setNextClicked,
        setBackClicked,
        information,
        setInformation: setInformationCallback,
        feedback,
        setFeedback,
        roofs, 
        setRoofs
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export const useStep = () => useContext(StepContext);
