import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { ServiceProvider, StepProvider, useTheme } from "contexts";
import { ThemeProvider } from "@mui/material/styles";
import { Constants, Enums, Themes } from "utils";
import { EmbeddedUser } from "types";

export const EmbeddedLayout = () => {
  const { theme } = useTheme();

  return (
    <ServiceProvider>
      <ThemeProvider theme={Themes.defaultTheme(theme)}>
        <StepProvider
          initInfo={
            {
              // TODO : uncomment the following lines to load a static design
              // design: {
              //   leadId: Constants.AERIALYTIC.LEAD_ID,
              //   proposalId: Constants.AERIALYTIC.PROPOSAL_ID,
              // },
            } as EmbeddedUser
          }
          initStep={Enums.EnumEmbeddedStep.Survey}
        >
          <Box>
            <Outlet />
          </Box>
        </StepProvider>
      </ThemeProvider>
    </ServiceProvider>
  );
};
