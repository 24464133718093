import { useEffect } from "react";
import { useApp, useStep, useTheme } from "contexts";
import { Box, Stack } from "@mui/material";
import { Constants, Enums } from "utils";
import { Custom } from "components";
import { AppointmentCheck } from "assets/images";

type Props = {};

export const Feedback = (props: Props) => {
  const { locale, t } = useApp();
  const { theme } = useTheme();
  const {
    feedback,
    information,
    goTo,
    isBackClicked,
    setNextDisabled,
    setInformation,
  } = useStep();

  useEffect(() => {
    if (isBackClicked) {
      setInformation(null);
      goTo(Enums.EnumEmbeddedStep.Survey);
    }
  }, [isBackClicked]);

  useEffect(() => setNextDisabled(true), []);

  return (
    <Stack
      height={1}
      maxWidth={600}
      margin="0 auto"
      direction="column"
      textAlign="center"
      alignItems="center"
      p={theme.spacing.xxxl}
      justifyContent="center"
    >
      {feedback === Enums.EnumEmbeddedStepFeedback.Sorry && (
        <Stack spacing={theme.spacing.md} alignItems="center">
          <Custom.Typography variant="h3">
            <Box component="span" color={theme.color.status.error.color}>
              We're sorry
            </Box>{" "}
            - We kindly request the homeowner to consider the solar option for
            this home.
          </Custom.Typography>
        </Stack>
      )}
      {feedback === Enums.EnumEmbeddedStepFeedback.ThankYou && (
        <Stack spacing={theme.spacing.md}>
          <Custom.Typography variant="h1" align="center">
            THANK YOU
          </Custom.Typography>
          <Custom.Typography variant="h3" align="center">
            We will get in touch with you as soon as possible.
          </Custom.Typography>
        </Stack>
      )}
      {feedback === Enums.EnumEmbeddedStepFeedback.NoCoverage && (
        <Stack spacing={theme.spacing.md} alignItems="center">
          <Custom.Typography variant="h3">
            <Box component="span" color={theme.color.status.error.color}>
              We're sorry
            </Box>{" "}
            - We currently don't provide services for the provided address.
          </Custom.Typography>
        </Stack>
      )}
      {feedback === Enums.EnumEmbeddedStepFeedback.Appointment && (
        <Stack spacing={theme.spacing.xxl} alignItems="center">
          <AppointmentCheck />
          <Custom.Typography variant="h2">
            Appointment Details
          </Custom.Typography>
          <Custom.Typography variant="h3" align="center">
            We have got you confirmed for your appointment!
          </Custom.Typography>
          <Custom.Typography variant="h2">
            {information.appointment.time}
          </Custom.Typography>
          <Custom.Typography variant="h3">
            {information.appointment.date.toLocaleDateString(
              locale,
              Constants.DATE_FORMAT
            )}
          </Custom.Typography>
        </Stack>
      )}
    </Stack>
  );
};
