import { Custom } from "components";
import { GoogleData, IGoogleData } from "data";
import { GoogleCalendarEvent } from "types";

export interface IGoogleService {
  all(): Promise<any[]>;
  info(calendarId: string): Promise<any | undefined>;
  create(calendarName: string, timeZone: string): Promise<string | "">;
  share(calendarId: string, email: string): Promise<boolean>;
  delete(calendarId: string): Promise<boolean>;
  find(calendarId: string, eventId: string): Promise<any | undefined>;
  insert(calendarId: string, date: Date | null, slot: string | null, duration: number, event?: GoogleCalendarEvent): Promise<string | undefined>;
  update(calendarId: string, eventId: string, event: GoogleCalendarEvent): Promise<boolean | undefined>;
  remove(calendarId: string, eventId: string): Promise<boolean | undefined>;
  availability(calendarId: string, date: Date | null, duration: number, startTime: number, endTime: number): Promise<Custom.ChipProps[]>;
}

export class GoogleService implements IGoogleService {
  googleData: IGoogleData;

  constructor() {
    this.googleData = new GoogleData();
  }

  /**
   * fetch all the calendars 
   * @returns {GoogleCalendar[]} list of calendars
   */

  async all() {
    return await this.googleData.all();
  }

  /**
   * create a new calendar
   * @param {string} calendarName the choosen name for the calendar
   * @param {string} timeZone the timezone for the calendar
   * @returns {string} email of the owner
   */

  async create(calendarName: string, timeZone: string) {
    return await this.googleData.create(calendarName, timeZone);
  }

  /**
   * delete a calendar
   * @param {string} calendarId the calendar id
   */

  async delete(calendarId: string) {
    return await this.googleData.delete(calendarId);
  }

  /**
   * sent an email to share a given calendar
   * @param {string} calendarId calendar id provided by google calendar api
   * @param {string} email email of the user that the calendar should be shared with
   */

  async share(calendarId: string, email: string) {
    return await this.googleData.share(calendarId, email);
  }

  /**
   * fetch the email of the calendar's owner
   * @param {string} calendarId calendar id provided by google calendar api
   * @returns {string} email of the owner
   */

  async info(calendarId: string) {
    return await this.googleData.info(calendarId);
  }

  /**
   * insert an event on the calendar
   * @param {string} calendarId calendar id provided by google calendar api
   * @param {Date} date date of the appointment
   * @param {string} slot time slot of the appointment
   * @param {number} duration the lenth of the appointment
   * @param {GoogleCalendarEvent} event the info that should be inserted on the event
   * @returns {Promise<string>}
   */

  async insert(calendarId: string, date: Date | null, slot: string | null, duration: number, event: GoogleCalendarEvent = {} as GoogleCalendarEvent): Promise<string | undefined> {
    return await this.googleData.insert(calendarId, date, slot, duration, event);
  }

  /**
   * fetch a specific event from the calendar
   * @param {string} calendarId calendar id provided by google calendar api
   * @param {string} eventId event id provided by google calendar api
   * @returns {GoogleCalendarEvent} event calendar object or null if not found
   */

  async find(calendarId: string, eventId: string) {
    return await this.googleData.find(calendarId, eventId);
  }

  /**
   * update a specific event in the calendar
   * @param {string} calendarId calendar id provided by google calendar api
   * @param {string} eventId event id provided by google calendar api
   * @returns {boolean} true if successful and false otherwise
   */

  async update(calendarId: string, eventId: string, event: GoogleCalendarEvent) {
    return await this.googleData.update(calendarId, eventId, event);
  }

  /**
   * delete a specific event from the calendar
   * @param {string} calendarId calendar id provided by google calendar api
   * @param {string} eventId event id provided by google calendar api
   * @returns {boolean} true if successful and false otherwise
   */

  async remove(calendarId: string, eventId: string) {
    return await this.googleData.remove(calendarId, eventId);
  }

  /**
   * fetch all the available time slots for a specific date
   * @param {string} calendarId the id of the calendar to fetch
   * @param {Date | null} date the date where the slots will be fetched
   * @param {number} duration the length of the slot
   * @param {number} startTime the start time of the shift
   * @param {number} endTime the end time of the shift
   * @return list of available slots
   */

  async availability(calendarId: string, date: Date | null, duration: number, startTime: number, endTime: number) {
    const response = await this.googleData.availability(calendarId, date, duration, startTime, endTime);
    return response.map((item: string) => {
      return { id: item, label: item };
    })
  }
}