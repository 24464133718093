import { useTheme } from "contexts";

// icons
import { RoomRounded } from "@mui/icons-material";

export const Marker = ({ _ }: any) => {
  const { theme } = useTheme();
  return (
    <RoomRounded
      sx={{
        color: "#ff0000",
        fontSize: theme.font.xxl,
        transform: `translate(-24px, -24px)`, // it is half the size of the svg
      }}
    />
  );
};
