import "assets/scss/pages/controller.scss";

import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useApp, useStep, useService, useTheme } from "contexts";
import { Constants, Enums } from "utils";
import { Validations } from "helpers";
import { Custom, Embedded, Progress } from "components";

type Props = {};

export const Home = (props: Props) => {
  console.log("props", props);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useApp();
  const { userId } = useParams();
  const { embedded, lead } = useService();
  const { setTheme, theme } = useTheme();
  const {
    goTo,
    isLoaded,
    setLoaded,
    currentStep,
    setNextStep,
    isNextDisabled,
    isBackDisabled,
    setBackClicked,
    setNextClicked,
    setUser,
    information,
    company,
    setCompany,
    user,
    roofs,
    setRoofs,
  } = useStep();

  const [appointmentUrl, setAppointmentUrl]= useState<string | null>(null);
  const [features, setFeatures]= useState<string[]>([]);

  const { user: profile } = useApp();

  useEffect(() => {
    if (!isLoaded && Validations.hasValue(userId)) fetchUser();
  }, [isLoaded, userId]);

  async function fetchUser() {
    let apiKey = queryParams.get("apiKey");
    const { payload } = await embedded.findUser(String(userId));
    const { payload: companyPayload } = await embedded.findCompany(
      String(apiKey)
    );

    let features = queryParams.get("features")?.split(",") ?? [];
    setFeatures(features);
    setCompany(companyPayload);
    setUser(payload);
    setTheme(payload.configuration.theme);

    const roofs = await embedded.findRoofTypes(String(apiKey));
    console.log("roofs", roofs);
    setRoofs(roofs.payload);

    setLoaded(true);
  }

  useEffect(() => {
    let appointmentUrl = queryParams.get("appointmentUrl");
    if(appointmentUrl && information) {
      let newAppointmentUrl = appointmentUrl
      .replace("{EMAIL}", information?.quote?.email)
      .replace("{PHONE}", information?.quote?.phone)
      .replace("{FIRST_NAME}", information?.quote?.firstName ?? "")
      .replace("{LAST_NAME}", information?.quote?.lastName ?? "")
      .replace("{ADDRESS}", information?.map?.address ?? "")
      .replace("{USAGE}", information?.details?.bill ?? "")

      setAppointmentUrl(newAppointmentUrl);
    }
  }, [information]);

  useEffect(() => {
    if(company) {
      document.title = company.name;
    }
  }, [company]);

  function handleEmailMeClick() {
    goTo(Enums.EnumEmbeddedStep.ConfirmInfo);
    setNextStep(Enums.EnumEmbeddedStep.Email);
  }

  function handleMakeAppointmentClick(url: string) {
    window.open(url, "_blank")?.focus();
  }

  return isLoaded ? (
    <Box className="al-embedded" bgcolor={theme.palette.background.color}>
      <Box className="al-header">
        {company.logo && (
          <Box alt="logo" component="img" src={`${company.logo}`} />
        )}
      </Box>
      <Box
        my={theme.spacing.md}
        className="al-content"
        bgcolor={theme.palette.theme}
        borderRadius={theme.border.radius}
      >
        {currentStep === Enums.EnumEmbeddedStep.Appointment && (
          <Embedded.Appointment />
        )}
        {currentStep === Enums.EnumEmbeddedStep.ConfirmInfo && (
          <Embedded.ConfirmInfo />
        )}
        {currentStep === Enums.EnumEmbeddedStep.Details && <Embedded.Details />}
        {currentStep === Enums.EnumEmbeddedStep.Email && <Embedded.Email />}
        {currentStep === Enums.EnumEmbeddedStep.Map && <Embedded.Map />}
        {currentStep === Enums.EnumEmbeddedStep.Overview && (
          <Embedded.Overview featureFlags={features} />
        )}
        {currentStep === Enums.EnumEmbeddedStep.Quote && <Embedded.Quote />}
        {currentStep === Enums.EnumEmbeddedStep.NoCoverage && <Embedded.NoCoverage />}
        {currentStep === Enums.EnumEmbeddedStep.Info && <Embedded.Info />}
        {currentStep === Enums.EnumEmbeddedStep.Survey && <Embedded.Survey />}
        {currentStep === Enums.EnumEmbeddedStep.Feedback && (
          <Embedded.Feedback />
        )}
      </Box>
      {![
        Enums.EnumEmbeddedStep.Email,
        Enums.EnumEmbeddedStep.ConfirmInfo,
        Enums.EnumEmbeddedStep.Appointment,
      ].includes(currentStep) && (
        <Box className="al-footer">
          <Custom.Button
            size="medium"
            secondary={true}
            disabled={isBackDisabled}
            onClick={() => setBackClicked(true)}
          >
            {currentStep === Enums.EnumEmbeddedStep.Feedback
              ? t.action.restart
              : t.action.back}
          </Custom.Button>
          {![
            Enums.EnumEmbeddedStep.Feedback,
            Enums.EnumEmbeddedStep.Overview,
          ].includes(currentStep) && (
            <Custom.Button
              size="medium"
              disabled={isNextDisabled}
              onClick={() => setNextClicked(true)}
            >
              {currentStep === Enums.EnumEmbeddedStep.Appointment
                ? t.action.done
                : t.action.next}
            </Custom.Button>
          )}
          {currentStep === Enums.EnumEmbeddedStep.Overview && (
            <Stack direction="row" spacing={theme.spacing.md}>
              {appointmentUrl && (
                <Custom.Button
                  size="medium"
                  disabled={isNextDisabled}
                  onClick={() => handleMakeAppointmentClick(appointmentUrl)}
                >
                  {t.action.make_appointment}
                </Custom.Button>
              )}
            </Stack>
          )}
        </Box>
      )}
    </Box>
  ) : (
    <Progress.PageProcess />
  );
};
