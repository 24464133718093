import { AuthService, IAuthService } from "./auth";
import { EmbeddedService, IEmbeddedService } from "./embedded";
import { GoogleService, IGoogleService } from "./google";
import { LeadService, ILeadService } from "./lead";

export interface IProvidedService {
  auth: IAuthService;
  embedded: IEmbeddedService;
  google: IGoogleService;
  lead: ILeadService;
}

export const initializeService = (): IProvidedService => ({
  auth: new AuthService(),
  embedded: new EmbeddedService(),
  lead: new LeadService(),
  google: new GoogleService(),
})