import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { ServiceProvider, useTheme } from "contexts";
import { ThemeProvider } from "@mui/material/styles";
import { Themes } from "utils";

export const PortalLayout = () => {
  const { theme } = useTheme();

  return (
    <ServiceProvider>
      <ThemeProvider theme={Themes.defaultTheme(theme)}>
        <Box minHeight="100vh" bgcolor={theme.palette.background.color}>
          <Outlet />
        </Box>
      </ThemeProvider>
    </ServiceProvider>
  );
};
