import React, { useEffect, useState } from "react";
import { useApp, useStep, useTheme } from "contexts";
import { Stack } from "@mui/material";
import { Embedded } from "components";
import { Enums } from "utils";
import { EmbeddedUser } from "types";

type Props = {};

const initialState = {
  question1: -1,
  question2: -1,
};

export const Survey = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const {
    goTo,
    setInformation,
    isNextClicked,
    setBackDisabled,
    setNextDisabled,
    setNextClicked,
    setFeedback,
  } = useStep();
  const [questions, setQuestions] = useState(initialState);

  const isValid =
    questions["question1"] !== -1 && questions["question2"] !== -1;

  useEffect(() => {
    if (isNextClicked && isValid) {
      if (isValid) {
        setInformation({ survey: questions } as EmbeddedUser);
        if (questions["question2"] === 0) {
          goTo(Enums.EnumEmbeddedStep.Map);
        } else if (questions["question2"] === 1) {
          goTo(Enums.EnumEmbeddedStep.Quote);
        }
      }
    }
    setNextClicked(false);
  }, [isNextClicked]);

  useEffect(() => setNextDisabled(!isValid), [isValid, setNextDisabled]);
  useEffect(() => setBackDisabled(true), []);

  function handleQuestionSelect(questionName: string, index: number) {
    setQuestions({ ...questions, [questionName]: index });
    if (questionName === "question1" && index === 1) {
      setFeedback(Enums.EnumEmbeddedStepFeedback.Sorry);
      goTo(Enums.EnumEmbeddedStep.Feedback);
    }
  }

  return (
    <Stack
      height={1}
      direction="column"
      alignItems="center"
      p={theme.spacing.xl}
      justifyContent="center"
      spacing={theme.spacing.xxl}
    >
      <Stack maxWidth={1} width={400} spacing={theme.spacing.xl}>
        {Object.keys(t.message.embedded.survey).map((item: string) => {
          return (
            <Embedded.QuestionSet
              key={item}
              title={t.message.embedded.survey[item].title}
              buttonTexts={t.message.embedded.survey[item].button}
              onSelect={(index: number) => handleQuestionSelect(item, index)}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
