import { ApiResponse, EmbeddedUser } from "types";
import { IEmbeddedUser } from "interfaces";
import { Enums } from "utils";
import { Auxiliars } from "helpers";
import { Embedded } from "assets/data";
import { ICompany, IRoofType } from "interfaces/embedded";
import axios from "axios";
import { AERIALYTIC } from "utils/constants";

export interface IEmbeddedData {
  findRoofTypes(token: string): Promise<ApiResponse<IRoofType[]>>;
  findCompany(token: string): Promise<ApiResponse<ICompany>>;
  findUser(userId: string): Promise<ApiResponse<IEmbeddedUser>>;
  insertLead(data: EmbeddedUser, token: string): Promise<ApiResponse<boolean>>;
}

export class EmbeddedData implements IEmbeddedData {
  async findCompany(token: string): Promise<ApiResponse<ICompany>> {
    let result = await axios.create({
      baseURL: AERIALYTIC.API_ENDPOINT,
      headers: {
        'Authorization': `Api-Key ${token}`
      }
    }).request<{ company: ICompany }>({
      method: 'GET',
      url: 'user/self'
    });
    return await Auxiliars.asyncMethod(() => ({
      status: Enums.EnumResponse.Success, payload: result.data.company
    }));
  }

  async findRoofTypes(token: string): Promise<ApiResponse<IRoofType[]>> {
    let result = await axios.create({
      baseURL: AERIALYTIC.API_ENDPOINT,
      headers: {
        'Authorization': `Api-Key ${token}`
      }
    }).request<{ company: ICompany }>({
      method: 'GET',
      url: 'hardware/roofs'
    });
    return await Auxiliars.asyncMethod(() => ({
      status: Enums.EnumResponse.Success, payload: result.data
    }));
  }

  async findUser(userId: string): Promise<ApiResponse<IEmbeddedUser>> {
    return await Auxiliars.asyncMethod(() => ({
      status: Enums.EnumResponse.Success, payload: Embedded.find((item: IEmbeddedUser) => item.userId === userId, null)
    }));
  }

  async insertLead(user: EmbeddedUser, token: string): Promise<ApiResponse<boolean>> {
    let result = await axios.create({
      baseURL: AERIALYTIC.API_ENDPOINT,
      headers: {
        'Authorization': `Api-Key ${token}`
      }
    }).request<{ lead: string }>({
      method: 'POST',
      url: 'iframe/lead/',
      data: {
        contacts: {
          first_name: user?.quote?.firstName ?? "",
          last_name: user?.quote?.lastName ?? "",
          email: user?.quote?.email ?? "",
          phone: user?.quote?.phone ?? ""
        },
        coordinates: `${user?.map?.coordinate.lat ?? 0},${user?.map?.coordinate.lng ?? 0}`,
        monthly_bill: Number(user?.details?.bill ?? 0),
        type: "Manual",
        rgb: "",
        dsm: "",
        resolution: 7.5
      }
    });
    return await Auxiliars.asyncMethod(() => ({
      status: Enums.EnumResponse.Success, payload: result.status === 200
    }));
  }
}