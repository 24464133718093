
enum EnumTheme {
  Light = 1,
  Dark = 2,
}

enum EnumUser {
  Admin = "Admin"
}

enum EnumFeedback {
  Success = "success",
  Error = "error",
  Info = "info"
}

enum EnumValidation {
  Required = "required",
  IsGraterThan = "isGraterThan",
  IsLessThan = "isLessThan",
  IsEquals = "isEquals",
  MinLength = "minLength",
  Function = "function",
  Regex = "regex"
}

enum EnumResponse {
  Success = 200,
  Error = 500,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  None = 0,
}

enum EnumMenu {
  Dashboard = 1,
  Map = 2,
  Search = 3,
  SavedList = 4,
  Settings = 5,
}

enum EnumIframeStatus {
  Ping = 1,
  Generated = 2,
  Lead = 3,
  Loaded = 4,
  Shading = 5,
  PanelPlacement = 6,
  Proposal = 7,
  Ready = 8,
  Error = 9,
}

enum EnumViewMode {
  Fullscreen = 1,
  SplitScreen = 2,
}

enum EnumMapboxLayer {
  Street = 1,
  Satellite = 2,
}

enum EnumMapboxType {
  All = "country,region,postcode,locality,neighborhood,address",
  Address = "address",
}

enum EnumEmbeddedStep {
  GetStarted = 0,
  Survey = 1,
  Quote = 2,
  Map = 3,
  Details = 4,
  Overview = 5,
  ConfirmInfo = 6,
  Email = 7,
  Appointment = 8,
  NoCoverage = 9,
  Info = 10,
  Feedback = 99,
}

enum EnumMarkerContextMenu {
  View = 1,
  Generate = 2,
  Edit = 3,
}

enum EnumEmbeddedStepMap {
  Search = 1,
  Map = 2,
}

enum EnumEmbeddedStepConfirmInfo {
  Home = 1,
  Form = 2,
}

enum EnumEmbeddedStepFeedback {
  Sorry = 1,
  ThankYou = 2,
  NoCoverage = 3,
  Appointment = 4,
}

enum EnumDayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export {
  EnumMenu,
  EnumUser,
  EnumTheme,
  EnumViewMode,
  EnumResponse,
  EnumFeedback,
  EnumDayOfWeek,
  EnumValidation,
  EnumMapboxType,
  EnumMapboxLayer,
  EnumIframeStatus,
  EnumEmbeddedStep,
  EnumEmbeddedStepMap,
  EnumMarkerContextMenu,
  EnumEmbeddedStepFeedback,
  EnumEmbeddedStepConfirmInfo,
}