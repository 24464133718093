import { IActivityStatus, IEmbeddedUser, IMarker, IUser } from "interfaces";

import ActivityStatus from "assets/data/activity_status.json";
import EmbeddedData from "assets/data/embedded.json";
import MarkerData from "assets/data/marker.json";
import SavedList from "assets/data/saved_list.json";
import USStatesData from "assets/data/us_states.json";
import User from "assets/data/user.json";

export const ActivityStatuses = ActivityStatus as IActivityStatus[];
export const Embedded = EmbeddedData as unknown as IEmbeddedUser[];
export const Markers = MarkerData as IMarker[];
export const SavedLists = SavedList as unknown as IMarker[];
export const USStates = USStatesData as unknown as string[];
export const Users = User as unknown as IUser[];