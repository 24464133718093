import { useState } from "react";
import { Custom, Input } from "components";
import { useApp, useTheme } from "contexts";
import { useForm } from "hooks";
import { Box, Grid, Stack } from "@mui/material";
import { Constants, Enums } from "utils";
import { Field, Fields, PairValue } from "types";
import { IEmbeddedUser } from "interfaces";
import { Combines } from "helpers";
import { USStates } from "assets/data";

export const AccountSettingsFields = {
  brand: "brand",
  accent: "accent",
  start: "start",
  end: "end",
  length: "length",
  overview: "overview",
  finance: "finance",
  design: "design",
  coverage: "coverage",
};

export type AccountSettingsForm = {
  [key: string]: any;
  brand?: File;
  accent: string;
  start: number;
  end: number;
  length: number;
  overview: boolean;
  finance: boolean;
  design: boolean;
  coverage: string[];
};

export const AccountSettingsInitialState: AccountSettingsForm = {
  brand: {} as File,
  accent: "",
  start: Constants.DEFAULT_CONFIGURATION.appointment.start,
  end: Constants.DEFAULT_CONFIGURATION.appointment.end,
  length: Constants.DEFAULT_CONFIGURATION.appointment.length,
  overview: Constants.DEFAULT_CONFIGURATION.settings.overview,
  finance: Constants.DEFAULT_CONFIGURATION.settings.finance,
  design: Constants.DEFAULT_CONFIGURATION.settings.design,
  coverage: [],
};

export const AccountSettings = () => {
  const { t } = useApp();
  const { theme, setTheme } = useTheme();

  const [fields, setFields] = useState<Fields>({
    [AccountSettingsFields.brand]: {
      helperText: t.helper_text.brand,
    } as Field,
    [AccountSettingsFields.accent]: {
      helperText: t.helper_text.accent_color,
      validates: {
        [Enums.EnumValidation.MinLength]: {
          length: 6,
          message: Combines.interpolate(t.message.validation.min_length, {
            count: 6,
          }),
        },
      },
    },
    [AccountSettingsFields.start]: {
      helperText: t.helper_text.appointment_start,
      validates: {
        [Enums.EnumValidation.IsLessThan]: {
          ref: AccountSettingsFields.end,
          message: Combines.interpolate(t.message.validation.is_less_than, {
            name: t.label.end_time,
          }),
        },
      },
    } as Field,
    [AccountSettingsFields.end]: {
      helperText: t.helper_text.appointment_end,
      validates: {
        [Enums.EnumValidation.IsGraterThan]: {
          ref: AccountSettingsFields.start,
          message: Combines.interpolate(t.message.validation.is_grater_than, {
            name: t.label.start_time,
          }),
        },
      },
    } as Field,
    [AccountSettingsFields.length]: {
      helperText: t.helper_text.appointment_length,
    } as Field,
    [AccountSettingsFields.coverage]: {
      helperText: t.helper_text.state_coverage,
    } as Field,
  });

  // TODO : it must be combined with the user information
  const { onChange, onValidate, onSubmit, values } =
    useForm<AccountSettingsForm>(
      AccountSettingsInitialState,
      undefined,
      submitCallback
    );

  async function submitCallback() {
    const { isValid, response } = onValidate(fields);
    setFields(response);
    if (isValid) {
      // TODO : it must be inserted into the database
      const newValue: IEmbeddedUser = {
        userId: "4",
        configuration: {
          appointment: {
            start: values.start,
            end: values.end,
            length: values.length,
          },
          brand: values.brand?.name ?? undefined,
          google: { calendarId: "" },
          settings: {
            overview: values.overview,
            finance: values.finance,
            design: values.design,
            coverage: values.coverage,
          },
          theme: {
            color: {
              accent: {
                color: values.accent,
              },
            } as any,
          } as any,
        },
      };
      setTheme(newValue.configuration.theme);
    }
  }

  return (
    <Box component="form" onSubmit={onSubmit} className="al-form">
      <Stack spacing={theme.spacing.md}>
        <Grid container spacing={theme.spacing.md}>
          <Grid item xs={12}>
            <Custom.Card>
              <Stack gap={theme.spacing.md}>
                <Custom.Typography variant="h5" weight={theme.font.medium}>
                  {t.title.brand_identity}
                </Custom.Typography>
                <Grid container spacing={theme.spacing.md}>
                  <Grid item xs={12} sm={6}>
                    <Input.File
                      name={AccountSettingsFields.brand}
                      label={t.label.brand}
                      accept="image/*"
                      onChange={onChange}
                      helperText={
                        fields[AccountSettingsFields.brand].error
                          ? fields[AccountSettingsFields.brand].errorText
                          : fields[AccountSettingsFields.brand].helperText
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Input.Color
                      name={AccountSettingsFields.accent}
                      value={values.accent}
                      onChange={onChange}
                      label={t.label.main_color}
                      helperText={
                        fields[AccountSettingsFields.accent].error
                          ? fields[AccountSettingsFields.accent].errorText
                          : fields[AccountSettingsFields.accent].helperText
                      }
                      error={fields[AccountSettingsFields.accent].error}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Custom.Card>
          </Grid>
          <Grid item xs={12}>
            <Custom.Card>
              <Stack gap={theme.spacing.md}>
                <Custom.Typography variant="h5" weight={theme.font.medium}>
                  {t.title.appointment_booking}
                </Custom.Typography>
                <Grid container spacing={theme.spacing.md}>
                  <Grid item xs={12} sm={4}>
                    <Input.Number
                      name={AccountSettingsFields.start}
                      min={0}
                      max={23}
                      value={values.start}
                      onChange={onChange}
                      label={t.label.start_time}
                      helperText={
                        fields[AccountSettingsFields.start].error
                          ? fields[AccountSettingsFields.start].errorText
                          : fields[AccountSettingsFields.start].helperText
                      }
                      error={fields[AccountSettingsFields.start].error}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Input.Number
                      name={AccountSettingsFields.end}
                      min={0}
                      max={23}
                      value={values.end}
                      onChange={onChange}
                      label={t.label.end_time}
                      helperText={
                        fields[AccountSettingsFields.end].error
                          ? fields[AccountSettingsFields.end].errorText
                          : fields[AccountSettingsFields.end].helperText
                      }
                      error={fields[AccountSettingsFields.end].error}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Input.Number
                      name={AccountSettingsFields.length}
                      min={10}
                      max={60}
                      step={5}
                      value={values.length}
                      onChange={onChange}
                      label={t.label.slot_length}
                      helperText={
                        fields[AccountSettingsFields.length].error
                          ? fields[AccountSettingsFields.length].errorText
                          : fields[AccountSettingsFields.length].helperText
                      }
                      error={fields[AccountSettingsFields.length].error}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Custom.Card>
          </Grid>
          <Grid item xs={12}>
            <Custom.Card>
              <Stack gap={theme.spacing.md}>
                <Custom.Typography variant="h5" weight={theme.font.medium}>
                  {t.title.feature_settings}
                </Custom.Typography>
                <Grid container spacing={theme.spacing.md}>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      px={theme.spacing.md}
                      spacing={theme.spacing.md}
                    >
                      <Custom.Checkbox
                        name={AccountSettingsFields.overview}
                        onChange={onChange}
                        label={t.title.overview}
                        checked={values.overview}
                      />
                      <Custom.Checkbox
                        name={AccountSettingsFields.finance}
                        onChange={onChange}
                        label={t.title.finance}
                        checked={values.finance}
                      />
                      <Custom.Checkbox
                        name={AccountSettingsFields.design}
                        onChange={onChange}
                        label={t.title.design}
                        checked={values.design}
                      />
                    </Stack>
                    <Box
                      mt={theme.component.input.helperText.mt}
                      mx={theme.component.input.helperText.mx}
                    >
                      <Custom.Typography
                        variant="h6"
                        color={theme.palette.font.accent}
                        letterSpacing={
                          theme.component.input.helperText.letterSpacing
                        }
                      >
                        {t.message.map.account_settings}
                      </Custom.Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Custom.Autocomplete
                      items={USStates}
                      onChange={onChange}
                      value={values.coverage}
                      name={AccountSettingsFields.coverage}
                      label={t.label.country_coverage}
                      helperText={
                        fields[AccountSettingsFields.coverage].helperText
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Custom.Card>
          </Grid>
        </Grid>
        <Stack direction="row" className="al-control" justifyContent="flex-end">
          <Custom.Button submit>{t.action.save}</Custom.Button>
        </Stack>
      </Stack>
    </Box>
  );
};
