import { Validations } from "helpers";
import { Navigate, useLocation } from "react-router-dom";

export const CheckApiKey = ({ children }: { children: any }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return Validations.hasValue(queryParams.get("apiKey")) ? (
    children
  ) : (
    <Navigate to={"/"} replace />
  );
};
