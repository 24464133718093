import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "contexts";
import { Constants, Routes } from "utils";
import { Custom } from "components";
import { Box, Stack } from "@mui/material";
import { Light } from "themes";
import { Combines } from "helpers";

type Props = {};

export const EmptyLayout = (props: Props) => {
  const navigate = useNavigate();
  const { theme, setTheme } = useTheme();

  useEffect(() => setTheme(Light), []);

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={theme.palette.background.accent}
    >
      <Stack direction="column" gap={theme.spacing.xl} justifyContent="center">
        <Stack direction="row" gap={theme.spacing.xl} justifyContent="center">
          <Custom.Button
            size="large"
            onClick={() =>
              navigate(
                Combines.interpolateURL(Routes.embedded.home + "/", {
                  userId: 2,
                }) +
                  Combines.toQueryString("", {
                    apiKey: Constants.AERIALYTIC.API_KEY,
                  })
              )
            }
          >
            Lead Gen
          </Custom.Button>
          <Custom.Button
            size="large"
            onClick={() =>
              navigate(
                Routes.map.home +
                  Combines.toQueryString("", {
                    apiKey: Constants.AERIALYTIC.API_KEY,
                  })
              )
            }
          >
            Map
          </Custom.Button>
          <Custom.Button
            size="large"
            onClick={() => navigate(Routes.portal.settings)}
          >
            Settings
          </Custom.Button>
        </Stack>
      </Stack>
    </Box>
  );
};
