import React, { useState } from "react";
import { Stack } from "@mui/material";
import { useTheme } from "contexts";
import { Custom } from "components";

type Props = {
  title: string;
  buttonTexts: string[];
  onSelect: (index: number) => void;
};

export const QuestionSet = (props: Props) => {
  const { theme } = useTheme();
  const [selected, setSelected] = useState<number>(-1);

  const handleSelect = (index: number) => {
    const newValue = selected === index ? -1 : index;
    setSelected(newValue);
    props.onSelect && props.onSelect(newValue);
  };

  return (
    <Stack direction="column" spacing={theme.spacing.xl}>
      <Custom.Typography variant="h3">{props.title}</Custom.Typography>
      <Stack direction="column" spacing={theme.spacing.sm} alignItems="center">
        {props.buttonTexts.map((text: string, i: number) => (
          <Custom.Button
            key={i}
            sx={{ width: 300 }}
            onClick={() => handleSelect(i)}
            secondary={selected === i ? false : true}
          >
            {text}
          </Custom.Button>
        ))}
      </Stack>
    </Stack>
  );
};
