import { useEffect } from "react";
import { useApp, useTheme } from "contexts";
import { Custom, Form } from "components";
import { Box, Grid, Stack } from "@mui/material";

type Props = {};

export const Settings = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  const pt = (theme.spacing.default * 1.2) / theme.spacing.factor;

  return (
    <Box px={theme.spacing.md} pt={pt}>
      <Stack spacing={theme.spacing.md}>
        <Custom.Typography variant="h2" sx={{ px: theme.spacing.md }}>
          {t.title.account_settings}
        </Custom.Typography>
        <Grid container>
          <Grid item xs={12}>
            <Form.AccountSettings />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};
