import { Custom, Input } from "components";
import { useApp, useService, useTheme } from "contexts";
import { useForm } from "hooks";
import { Constants, Enums } from "utils";
import { Auxiliars, Validations } from "helpers";
import { Box, Grid, Stack } from "@mui/material";
import { IMarker } from "interfaces";
import { Field, Fields } from "types";
import { useState } from "react";

export const PropertyFields = {
  name: "name",
  phone: "phone",
  email: "email",
  address: "address",

  roofType: "roofType",
  roofAge: "roofAge",
  average_bill: "average_bill",
  utility_company: "utility_company",
  electricity_usage: "electricity_usage",

  status: "status",
};

export type PropertyForm = {
  [key: string]: any;
  name: string;
  phone: string;
  email: string;
  address: string;

  roofType: string;
  roofAge: string;
  average_bill: number;
  utility_company: string;
  electricity_usage: number;

  status: string;
};

export const PropertyInitialState: PropertyForm = {
  name: "",
  phone: "",
  email: "",
  address: "",
  roofType: "",
  roofAge: "",
  average_bill: 0,
  utility_company: "",
  electricity_usage: 0,
  status: "",
};

type Props = {
  marker: IMarker;
  onCancel: () => void;
  onSubmit: (marker: IMarker) => void;
};

export const Property = ({ marker, ...props }: Props) => {
  const { theme } = useTheme();
  const { lead } = useService();
  const { t, setFeedback } = useApp();

  const [fields, setFields] = useState<Fields>({
    [PropertyFields.phone]: {
      validates: {
        [Enums.EnumValidation.Function]: {
          fn: (value: string): boolean => Validations.isPhone(value),
          message: t.message.validation.is_phone_number,
        },
      },
    } as Field,
    [PropertyFields.email]: {
      validates: {
        [Enums.EnumValidation.Function]: {
          fn: (value: string): boolean => Validations.isEmail(value),
          message: t.message.validation.is_email_address,
        },
      },
    } as Field,
  });

  const { onChange, onValidate, onSubmit, values } = useForm<PropertyForm>(
    { ...PropertyInitialState, ...marker } as PropertyForm,
    undefined,
    submitCallback
  );

  async function submitCallback() {
    const { isValid, response } = onValidate(fields);
    setFields(response);
    if (isValid) {
      const newValues = Auxiliars.removeFromObject(values) as IMarker;
      const { status, payload } = await lead.updateMarker(marker.id, newValues);
      if (status === Enums.EnumResponse.Success) {
        props.onSubmit && props.onSubmit(newValues);
      } else {
        setFeedback({
          message: t.message.auth.wrong_credentials,
          severity: Enums.EnumFeedback.Error,
        });
      }
    }
  }

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Grid container p={theme.spacing.md}>
        <Grid item xs={12} mb={theme.spacing.md}>
          <Stack gap={theme.spacing.md}>
            <Grid container spacing={theme.spacing.md}>
              <Grid item xs={12}>
                <Custom.TextField
                  name={PropertyFields.name}
                  value={values.name}
                  onChange={onChange}
                  label={t.label.name}
                />
              </Grid>
              <Grid item xs={12}>
                <Custom.TextField
                  name={PropertyFields.phone}
                  value={values.phone}
                  onChange={onChange}
                  label={t.label.phone}
                  mask={Constants.INPUT_MASK.PHONE}
                  helperText={
                    fields[PropertyFields.phone].error
                      ? fields[PropertyFields.phone].errorText
                      : undefined
                  }
                  error={fields[PropertyFields.phone].error}
                />
              </Grid>
              <Grid item xs={12}>
                <Custom.TextField
                  name={PropertyFields.email}
                  value={values.email}
                  onChange={onChange}
                  label={t.label.email}
                  helperText={
                    fields[PropertyFields.email].error
                      ? fields[PropertyFields.email].errorText
                      : undefined
                  }
                  error={fields[PropertyFields.email].error}
                />
              </Grid>
              <Grid item xs={12}>
                <Custom.TextField
                  name={PropertyFields.address}
                  value={values.address}
                  onChange={onChange}
                  label={t.label.address}
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack gap={theme.spacing.md}>
            <Grid container spacing={theme.spacing.md}>
              <Grid item xs={6}>
                <Custom.TextField
                  name={PropertyFields.roofType}
                  value={values.roofType}
                  onChange={onChange}
                  label={t.label.roof_type}
                />
              </Grid>
              <Grid item xs={6}>
                <Custom.TextField
                  name={PropertyFields.roofAge}
                  value={values.roofAge}
                  onChange={onChange}
                  label={t.label.roof_age}
                />
              </Grid>
              <Grid item xs={6}>
                <Input.Number
                  name={PropertyFields.average_bill}
                  min={20}
                  max={500}
                  step={20}
                  value={values.average_bill}
                  onChange={onChange}
                  label={t.label.average_bill}
                />
              </Grid>
              <Grid item xs={6}>
                <Input.Number
                  name={PropertyFields.electricity_usage}
                  min={0}
                  max={15000}
                  step={500}
                  value={values.electricity_usage}
                  onChange={onChange}
                  label={t.label.electricity_usage}
                />
              </Grid>
              <Grid item xs={12}>
                <Custom.TextField
                  name={PropertyFields.utility_company}
                  value={values.utility_company}
                  onChange={onChange}
                  label={t.label.utility_company}
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        px={theme.spacing.md}
        justifyContent="space-between"
      >
        <Custom.Button secondary onClick={props.onCancel}>
          {t.action.cancel}
        </Custom.Button>
        <Custom.Button submit>{t.action.submit}</Custom.Button>
      </Stack>
    </Box>
  );
};
