import { useTheme } from "contexts";
import { Divider, Stack } from "@mui/material";
import { Custom, Embedded } from "components";
import { EmbeddedUser, SolarSavings } from "types";
import { Conversions } from "helpers";
import { Enums } from "utils";

type Props = {
  finance: SolarSavings;
  information: EmbeddedUser;
};

export const Finance = (props: Props) => {
  const { theme } = useTheme();

  const lifetimeSavings: number = props.finance && props.finance.estimated25YearSavings ? props.finance.estimated25YearSavings : 0;
  const yearlySavings: number = props.finance && props.finance.estimatedYearlySavings ? props.finance.estimatedYearlySavings : 0;

  if (lifetimeSavings === 0 || yearlySavings === 0) {
    return (
      <Stack
        direction="column"
        p={theme.spacing.sm}
        justifyContent="center"
        spacing={theme.spacing.md}
      >
        <Custom.Typography variant="h5" weight="bold" align="center">
          FINANCIAL BREAKDOWN
        </Custom.Typography>
        <Stack paddingTop={15}>
          <Embedded.Progress status={Enums.EnumIframeStatus.Proposal} generated={false} />
        </Stack>
      </Stack>
    )
  }
  
  return (
    <Stack
      direction="column"
      alignItems="center"
      p={theme.spacing.sm}
      spacing={theme.spacing.xxxl}
    >
      <Custom.Typography variant="h5" weight="bold" align="center">
        FINANCIAL BREAKDOWN
      </Custom.Typography>
      <Stack
        width={300}
        maxWidth={1}
        direction="column"
        p={theme.spacing.sm}
        spacing={theme.spacing.sm}
        borderRadius={theme.border.radius}
        bgcolor={theme.palette.background.color}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Custom.Typography variant="h4">
            {Conversions.toMoney(yearlySavings)}
          </Custom.Typography>
          <Custom.Typography variant="h6">
            Estimated First Year Savings
          </Custom.Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Custom.Typography variant="h4">
            {Conversions.toMoney(lifetimeSavings)}
          </Custom.Typography>
          <Custom.Typography variant="h6">
            Estimated 25-Year Savings
          </Custom.Typography>
        </Stack>
      </Stack>
      <Stack width={1} direction="column" spacing={theme.spacing.xs}>
        <Stack
          direction="row"
          alignItems="center"
          px={theme.spacing.sm}
          justifyContent="space-between"
        >
          <Custom.Typography variant="h6">System Cost</Custom.Typography>
          <Custom.Typography variant="h6" weight="bold">
            {Conversions.toMoney(props.finance.systemCost)}
          </Custom.Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          px={theme.spacing.sm}
          justifyContent="space-between"
        >
          <Custom.Typography variant="h6">Credits and Rebates</Custom.Typography>
          <Custom.Typography variant="h6" weight="bold">
            {Conversions.toMoney(props.finance.rebateAmount)}
          </Custom.Typography>
        </Stack>
        <Custom.Divider />
        <Stack
          direction="row"
          alignItems="center"
          px={theme.spacing.sm}
          justifyContent="space-between"
        >
          <Custom.Typography variant="h6">Total Cost</Custom.Typography>
          <Custom.Typography variant="h6" weight="bold">
            {Conversions.toMoney(props.finance.totalCost)}
          </Custom.Typography>
        </Stack>
      </Stack>
      <Custom.Typography variant="h6">
        *These figures are provided as estimates only, all calculations must be
        verified.
      </Custom.Typography>
    </Stack>
  );
};
