import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Constants, Geocoder } from "utils";
import { Validations } from "helpers";
import { Coordinate } from "types";
import { Box } from "@mui/material";
import { Google } from "components";

type Props = {
  coordinate?: Coordinate;
  onChange: (coordinate: Coordinate, address: string) => void;
};

export const Map = (props: Props) => {
  const [markerCoordinate, setMarkerCoordinate] = useState<Coordinate>({
    lat: props.coordinate?.lat ?? Constants.GOOGLE_MAPS.LATITUDE,
    lng: props.coordinate?.lng ?? Constants.GOOGLE_MAPS.LONGITUDE,
  });

  useEffect(() => {
    if (props.coordinate) {
      fetchAddress(props.coordinate.lat, props.coordinate.lng);
    }
  }, [props.coordinate]);

  function createMapOptions(maps: GoogleMapReact.Maps) {
    return {
      mapTypeId: "satellite",
      draggable: true,
      fullscreenControl: false,
      zoomControl: false,
      panControl: false,
      rotateControl: false,
      scaleControl: false,
      maxZoom: Constants.GOOGLE_MAPS.MAX_ZOOM,
      minZoom: Constants.GOOGLE_MAPS.MIN_ZOOM,
      tilt: 0,
    } as GoogleMapReact.MapOptions;
  }

  async function fetchAddress(latitude: number, longitude: number) {
    const response = await Geocoder.fetchAddress(latitude, longitude);
    if (Validations.hasValue(response)) {
      props.onChange &&
        props.onChange(
          { lat: latitude, lng: longitude } as Coordinate,
          response
        );
    }
  }

  function handleClick(value: GoogleMapReact.ClickEventValue) {
    const { lat, lng } = value;
    setMarkerCoordinate({ lat, lng });
    fetchAddress(lat, lng);
  }

  return (
    <Box sx={{ height: "100vh", width: 1, position: "relative" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: Constants.GOOGLE.API_KEY }}
        center={props.coordinate}
        defaultZoom={Constants.GOOGLE_MAPS.ZOOM}
        options={createMapOptions}
        onClick={handleClick}
        children={
          <Google.Marker
            lat={markerCoordinate.lat}
            lng={markerCoordinate.lng}
          />
        }
      />
    </Box>
  );
};
