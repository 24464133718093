import axios from 'axios';
import { Combines } from 'helpers';
/**
 * responsible for creating the instance of axios in order to make requests to the Google API (0auth2)
 */

const oauth = (baseUrl: string, params: object) => axios.create({
  baseURL: Combines.interpolate(baseUrl, params),
});

/**
 * responsible for creating the instance of axios in order to make requests to Google Calendar API
 */

const calendar = (baseUrl: string, token: string, params: object = {}) => axios.create({
  baseURL: Combines.interpolate(baseUrl, params),
  headers: {
    common: {
      'Authorization': `Bearer ${token}`
    }
  }
});

export {
  oauth,
  calendar
}