import { useEffect, useState } from "react";
import { useTheme, useStep, useApp, useService } from "contexts";
import { Stack } from "@mui/material";
import { Enums } from "utils";
import { Custom, Form } from "components";
import { EmbeddedUser } from "types";
import { useLocation } from "react-router-dom";

type Props = {};

export const Info = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const {
    goTo,
    information,
    isBackClicked,
    isNextClicked,
    setNextClicked,
    setNextDisabled,
    setFeedback,
  } = useStep();

  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (isNextClicked && isValid) {
      if (information.survey.question2 === 0) {
        goTo(Enums.EnumEmbeddedStep.Overview);
      } else if (information.survey.question2 === 1) {
        setFeedback(Enums.EnumEmbeddedStepFeedback.ThankYou);
        goTo(Enums.EnumEmbeddedStep.Feedback);
      }
    }
    setNextClicked(false);
  }, [isNextClicked]);

  useEffect(() => {
    if (isBackClicked) {
      if (information.survey.question2 === 0) {
        goTo(Enums.EnumEmbeddedStep.Details);
      } else if (information.survey.question2 === 1) {
        goTo(Enums.EnumEmbeddedStep.Survey);
      }
    }
  }, [isBackClicked]);

  useEffect(() => setNextDisabled(!isValid), [setNextDisabled, isValid]);

  function handleFormUpdate(values: Form.QuoteForm, isValid: boolean) {
    setIsValid(isValid);
  }

  return (
    <Stack
      height={1}
      direction="column"
      p={theme.spacing.lg}
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      spacing={theme.spacing.xl}
    >
      <Stack maxWidth={1} width={700} spacing={theme.spacing.xl}>
        <Custom.Typography variant="h2">
          {t.message.embedded.quote.title}
        </Custom.Typography>
        <Stack spacing={theme.spacing.md}>
          {information.survey.question2 === 0 ? (
            <Custom.Typography variant="h5">
              {t.message.embedded.quote.description_roof}
            </Custom.Typography>
          ) : (
            <Custom.Typography variant="h5">
              {t.message.embedded.quote.description_ground}
            </Custom.Typography>
          )}
          <Custom.Typography variant="h5">
            {t.message.embedded.quote.footnote}
          </Custom.Typography>
        </Stack>
      </Stack>
      <Form.Quote onUpdate={handleFormUpdate} />
    </Stack>
  );
};
