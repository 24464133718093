import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { IMarker } from "interfaces";
import { Sanitizes } from "helpers";

// icons
import {
  ContentCopyRounded,
  Mail,
  PersonRounded,
  Phone,
  PlaceRounded,
} from "@mui/icons-material";
import { useApp } from "contexts";

type Props = {
  marker: IMarker;
};

export const OwnerInfo = ({ marker }: Props) => {
  const { t } = useApp();

  function handleCopyClipboardClick(value: string) {
    navigator.clipboard.writeText(value);
  }

  return (
    <List sx={{ width: 1 }}>
      <ListItemButton onClick={() => handleCopyClipboardClick(marker.name)}>
        <ListItemIcon>
          <PersonRounded />
        </ListItemIcon>
        <ListItemText primary={marker.name ?? t.placeholder.no_value} />
        <ContentCopyRounded />
      </ListItemButton>

      <ListItemButton onClick={() => handleCopyClipboardClick(marker.email)}>
        <ListItemIcon>
          <Mail />
        </ListItemIcon>
        <ListItemText primary={marker.email ?? t.placeholder.no_value} />
        <ContentCopyRounded />
      </ListItemButton>

      <ListItemButton onClick={() => handleCopyClipboardClick(marker.phone)}>
        <ListItemIcon>
          <Phone />
        </ListItemIcon>
        <ListItemText
          primary={Sanitizes.toPhone(marker.phone) ?? t.placeholder.no_value}
        />
        <ContentCopyRounded />
      </ListItemButton>

      <ListItemButton onClick={() => handleCopyClipboardClick(marker.address)}>
        <ListItemIcon>
          <PlaceRounded />
        </ListItemIcon>
        <ListItemText primary={marker.address ?? t.placeholder.no_value} />
        <ContentCopyRounded />
      </ListItemButton>
    </List>
  );
};
