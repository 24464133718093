import { useEffect, useState } from "react";
import { useApp, useStep, useTheme } from "contexts";
import { Box, Stack } from "@mui/material";
import { Email as ThumbEmail } from "assets/images";
import { Custom, Form } from "components";
import { Enums } from "utils";
import { EmbeddedUser } from "types";

type Props = {};

export const ConfirmInfo = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const { goTo, information, nextStep, setInformation } = useStep();

  const [step, setStep] = useState<Enums.EnumEmbeddedStepConfirmInfo>(
    Enums.EnumEmbeddedStepConfirmInfo.Home
  );

  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {}, [information.quote]);

  function handleSwitchViewClick() {
    setStep(Enums.EnumEmbeddedStepConfirmInfo.Home);
  }

  function handleUpdateInfoClick() {
    setStep(Enums.EnumEmbeddedStepConfirmInfo.Form);
  }

  function handleFormUpdate(values: Form.QuoteForm, isValid: boolean) {
    setInformation({ quote: { ...values } } as EmbeddedUser);
    setIsValid(isValid);
  }

  function handleNavigationClick() {
    if (nextStep) goTo(nextStep);
  }

  return (
    <Stack height={1} alignItems="center" justifyContent="center">
      {step === Enums.EnumEmbeddedStepConfirmInfo.Home && (
        <Stack spacing={theme.spacing.xxl} alignItems="center">
          <Box width={100} height={100} component="img" src={ThumbEmail} />
          <Stack spacing={theme.spacing.xs} alignItems="center">
            <Custom.Typography variant="h1" weight="bold">
              Comfirm Information
            </Custom.Typography>
            <Custom.Typography variant="h5" weight="black">
              Is{" "}
              <Custom.Typography
                variant="h4"
                weight="bold"
                color={theme.color.accent.color}
              >
                {information.quote.email}{" "}
              </Custom.Typography>{" "}
              your email address and{" "}
              <Custom.Typography
                variant="h4"
                weight="bold"
                color={theme.color.accent.color}
              >
                {information.quote.phone}{" "}
              </Custom.Typography>{" "}
              your phone number?
            </Custom.Typography>
          </Stack>
          <Stack spacing={theme.spacing.md} alignItems="center">
            <Custom.Button onClick={handleNavigationClick}>
              {t.action.confirm}
            </Custom.Button>
            <Custom.Button text onClick={handleUpdateInfoClick}>
              {t.action.update}
            </Custom.Button>
          </Stack>
        </Stack>
      )}
      {step === Enums.EnumEmbeddedStepConfirmInfo.Form && (
        <Stack
          alignItems="center"
          p={theme.spacing.xxl}
          spacing={theme.spacing.xxl}
        >
          <Custom.Typography align="center" weight="bold" variant="h3">
            {t.action.confirm}
          </Custom.Typography>
          <Form.Quote onUpdate={handleFormUpdate} />
          <Custom.Button onClick={handleSwitchViewClick} disabled={!isValid}>
            {t.action.update}
          </Custom.Button>
        </Stack>
      )}
    </Stack>
  );
};
