import { useEffect, useState } from "react";
import { Box, Fade, LinearProgress, Stack } from "@mui/material";
import { Constants, Enums } from "utils";
import { useTheme } from "contexts";
import { Custom } from "components";

type Props = {
  generated: boolean;
  status: Enums.EnumIframeStatus;
};

const MessageContainer = ({
  visible,
  children,
}: {
  visible: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Fade in={visible}>
      <Box
        sx={{
          width: 1,
          maxHeight: 1,
          maxWidth: 360,
          minHeight: 100,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        {children}
      </Box>
    </Fade>
  );
};

export const Progress = ({
  generated = false,
  status = Enums.EnumIframeStatus.Ping,
}: Props) => {
  const { theme } = useTheme();

  const [progress, setProgress] = useState<number>(0);
  const [messageVisible, setMessageVisible] = useState(true);
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    clearInterval(timer);
    setMessageVisible(false);
    setShowPlaceholder(false);

    setTimer(
      setInterval(() => {
        setShowPlaceholder((currentShowPlaceholder) => {
          if (currentShowPlaceholder) {
            setMessageVisible(true);
          }
          return !currentShowPlaceholder;
        });
      }, Constants.REFRESH_RATE.PLACEHOLDER_THRESHOLD)
    );

    const timeout = setTimeout(() => {
      setMessage(getStatusMessage());
      setMessageVisible(true);
    }, Constants.REFRESH_RATE.TRANSITION_MESSAGE);

    return () => {
      clearInterval(timer);
      clearTimeout(timeout);
    };
  }, [status]);

  function getStatusMessage() {
    if (generated) return "The design is being retrieved and loaded.";
    switch (status) {
      case Enums.EnumIframeStatus.Ping:
        return "Getting everything ready to design.";
      case Enums.EnumIframeStatus.Generated:
        return "Gathering geospatial data for modeling.";
      case Enums.EnumIframeStatus.Lead:
        setProgress(20);
        return "Using AI to create the 3D model of the house.";
      case Enums.EnumIframeStatus.Loaded:
        setProgress(40);
        return "Identifying obstacles, trees, and shading.";
      case Enums.EnumIframeStatus.Shading:
        setProgress(60);
        return "Placing solar panels based on productivity.";
      case Enums.EnumIframeStatus.PanelPlacement:
        setProgress(80);
        return "Getting the interactive model ready.";
      case Enums.EnumIframeStatus.Proposal:
        setProgress(0);
        return "Finishing up the cost efficiency calculations.";
      case Enums.EnumIframeStatus.Ready:
        return "Ready!";
      case Enums.EnumIframeStatus.Error:
        return "An error occurred.";
      default:
        return "";
    }
  }

  return (
    <Stack
      height={1}
      alignItems="center"
      px={theme.spacing.xl}
      mb={theme.spacing.lg}
      flexDirection="column"
      justifyContent="center"
    >
      {!showPlaceholder && (
        <MessageContainer visible={messageVisible && !showPlaceholder}>
          <Custom.Typography variant="h2">
            {message}
          </Custom.Typography>
        </MessageContainer>
      )}
      {showPlaceholder && (
        <MessageContainer visible={showPlaceholder}>
          <Custom.Typography variant="h2">
            Your custom design is on its way, hold on.
          </Custom.Typography>
        </MessageContainer>
      )}
      <LinearProgress
        value={progress}
        variant={progress === 0 ? "indeterminate" : "determinate"}
        sx={{
          width: 1,
          height: 6,
          maxWidth: 400,
          mt: theme.spacing.xl,
          borderRadius: theme.border.radius,
          backgroundColor: theme.palette.background.color,
          "& .MuiLinearProgress-bar": {
            borderRadius: theme.border.radius,
            backgroundColor: theme.color.accent.color,
          },
        }}
      />
    </Stack>
  );
};
